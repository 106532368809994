/**
 * This Class makes it easy to generate a URL string to request a static map image using the TomTom
 * [Maps API - Static Image](MAPS_RASTER_STATIC_IMAGE_URL).
 *
 * Parameters need to be passed to the method, which generates the URL string.
 *
 * @example
 * ```js
 * // Passing an object with all the configuration
 * function callbackFn(urlString) {
 *   console.log(urlString);
 * }
 * tt.services.staticImage({
*   key: <Your API key>,
*   zoom: 15,
 *   bbox: [
 *     [40.712, -74.227],
 *     [40.774, -74.125]
 *   ]
 * }).then(callbackFn);
 * ```
 *
 * @class staticImage
 * @namespace Services.services
 * @uses KeyMixin
 * @uses ViewMixin
 * @module Services
 * @constructor
 * @param {Object} [options] Options to be passed to the routing call,
 *     or an array of such options objects to perform a batch request.
 */
import utils from 'services/utils';
import { staticMapImageEndpoint } from 'endpoints';
import {SERVICE_TYPES} from 'Core/serviceTypes';
import * as validators from 'services/validators';
import converters from 'services/converters';

/**
 * @ignore
 * Static Map API endpoint.
 */
const baseUrl = staticMapImageEndpoint;
const MAX_WIDTH = 8192;
const MAX_HEIGHT = 8192;
const SUPPORTED_IMAGE_FORMATS_PER_LAYER = {
    'basic': ['jpg', 'jpeg', 'png'],
    'hybrid': ['png'],
    'labels': ['png']
};

const fields = {
    /**
     * @attribute layer
     * @param {"basic"|"hybrid"|"labels"} [options.layer="basic"] The base map's layer to be used.
     */
    layer: {
        validators: [validators.oneOfValue(['basic', 'hybrid', 'labels'], 'layers')],
        defaultValue: 'basic'
    },

    /**
     * @attribute style
     * @param {"main"|"night"} [options.style="main"] The base map style to be used.
     */
    style: {
        validators: [validators.oneOfValue(['main', 'night'], 'styles')],
        defaultValue: 'main'
    },

    /**
     * @attribute format
     * @param {"png"|"jpg"|"jpeg"} [options.format="png"] The format of the image to be requested.
     */
    format: {
        validators: [validators.oneOfValue(['png', 'jpg', 'jpeg'], 'formats')],
        defaultValue: 'png'
    },

    key: {
        validators: [validators.key]
    },

    /**
     * @attribute zoom
     * @param {Number} [options.zoom=12] Positive integer between 0 and 22.
     */
    zoom: {
        validators: [validators.integerInInterval(0, 23)],
        converters: [converters.integer]
    },

    /**
     * @attribute center
     * @param {Maps.LngLat} [options.center] Coordinates to the center of the view.
     */
    center: {
        converters: [converters.point],
        validators: [validators.point]
    },

    /**
     * @attribute width
     * @param {Number} [options.width] Width of the image in pixels.
     */
    width: {
        validators: [validators.integerInInterval(1, MAX_WIDTH)]
    },

    /**
     * @attribute height
     * @param {Number} [options.height] Height of the image in pixels.
     */
    height: {
        validators: [validators.integerInInterval(1, MAX_HEIGHT)]
    },

    /**
     * @attribute bbox
     * @param {Maps.LngLatBounds} [options.bbox] Bounding box.
     */
    bbox: {
        validators: [validators.boundingBox],
        converters: [converters.boundingBox]
    },

    view: {
        validators: [validators.oneOfValue(
            ['Unified', 'IL', 'IN', 'MA', 'PK', 'AR', 'Arabic', 'TR', 'RU', 'CN'],
            'view')]
    },

    /* eslint-disable max-len */
    /**
     * You can find the list of supported languages [here](MAPS_VECTOR_TILE_SUPPORTED_LANGS_URL).
     * @attribute language
     * @param {String} [options.language="NGT"] Language to use for the labels.
     *
     */
    /* eslint-disable max-len */
    language: {
        validators: [validators.mapsLanguage]
    }
};

/**
 * @ignore
 * Validate a combination of properties. Done here because chain.js does not support such behaviour.
 * @throws Error
 * @param {Object} data
 */
function validateCombineProperties(data) {

    // Executing additional more complex validations
    if (!SUPPORTED_IMAGE_FORMATS_PER_LAYER[data.layer] ||
        SUPPORTED_IMAGE_FORMATS_PER_LAYER[data.layer].indexOf(data.format) === -1) {
        throw new Error('Unsupported layer. Please change to PNG or use basic layer.');
    }

    if (data.bbox && data.center) {
        // If both are used - service will return a HTTP 400 response.
        throw new Error('The bbox and center properties cannot be used together');
    }

    if (!data.bbox && !data.center) {
        throw new Error('Either bbox or center property must be provided');
    }

    if (data.bbox && (utils.isValidNumber(data.width) || utils.isValidNumber(data.height))) {
        // If both are used - service will return a HTTP 400 response.
        throw new Error('The bbox, width and height properties cannot be used together');
    }
}

function convertBBoxToString(bbox) {
    return bbox.minLon + ',' + bbox.minLat + ',' + bbox.maxLon + ',' + bbox.maxLat;
}

function serializeToStringIfNecessary(propertyName, propertyValue) {
    if (propertyName === 'bbox') {
        propertyValue = convertBBoxToString(propertyValue);
    } else if (propertyName === 'zoom') {
        return String(parseInt(propertyValue, 10));
    } else if (propertyName === 'center') {
        const splitCenter = propertyValue.split(',');
        const lngLatCenter = [parseFloat(splitCenter[1]), parseFloat(splitCenter[0])];
        return lngLatCenter[0] + ',' + lngLatCenter[1];
    }
    return propertyValue;
}

/**
 * @ignore
 * Build the URL string using the available configuration.
 * @param {Object} data
 * @return {String}
 */
function toUrl(data) {
    const querySegments = Object.keys(fields).reduce(function(segments, propertyName) {
        let propertyValue = data[propertyName];
        if (propertyValue !== undefined) {
            propertyValue = serializeToStringIfNecessary(propertyName, propertyValue);
            segments.push(propertyName + '=' + encodeURI(propertyValue));
        }
        return segments;
    }, []);
    return baseUrl + '?' + querySegments.join('&');
}

export function staticImage(core) {
    function handleServiceCall(data) {
        validateCombineProperties(data);
        return toUrl(data);
    }
    return core.serviceFactory(fields, SERVICE_TYPES.MAP, 'staticImage', handleServiceCall);
}
