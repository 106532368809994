/* eslint-disable max-len */
/* jshint ignore:start */
/**
 * Calculate a reachable range implementation using the TomTom Reachable Range API.
 *
 * Makes a route request using the TomTom [Routing API - Calculate Reachable Range](CALCULATE_REACHABLE_RANGE_URL).
 *
 * Parameters need to be passed to the constructor.
 *
 *
 * The Calculate Reachable Range service calculates a set of locations that can
 * be reached from the origin point, subject to the available fuel or energy
 * budget that is specified in the request.
 *
 * The information returned is a polygon boundary in counterclockwise orientation
 * and the precise polygon center (the result of map-matching the origin point).
 *
 * ### Response
 * This service extends the API response by providing the following methods:
 * * `toGeoJson()`, converts range data into
 * a Feature with <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.6">Polygon</a> geometry.
 * * `getTrackingId()`, which returns the `Tracking-ID` associated with the request.
 *
 * To read more about services responses take a look at {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 * or dive into <a target="_blank" rel=”noopener”
 * href="https://developer.tomtom.com/maps-sdk-web-js/functional-examples#examples,code,reachable-range.html">
 * Reachable range example</a> code.
 *
 * @class calculateReachableRange
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses CommonRoutingParameters
 * @uses CalculateReachableRangeParameters
 * @uses ProtocolMixin
 * @uses BatchMixin
 * @uses ReturnsToGeoJsonMixin
 * @uses AbortSignalMixin
 *
 * @constructor
 *
 * @param {Object} [options] Options to be passed to the routing call,
 *     or an array of such options objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 *
 * @example
 * ```javascript
 *     tt.services.calculateReachableRange({
 *         key: <Your API key>,
 *         origin: '19.454722,51.776667',
 *         fuelBudgetInLiters: 5,
 *         constantSpeedConsumptionInLitersPerHundredkm: ["10,20", "15,30"],
 *     }).then(function(rangeData) {
 *           console.log(rangeData.toGeoJson());
 *      })
 *       .catch(function(error) {
 *           console.error(error);
 *       });
 * ```
 *
 */
/* eslint-enable max-len */
/* jshint ignore:end */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import calculateReachableRangeParameters from './calculateReachableRangeParameters';
import reachableRangeModel from '../../model/routing/calculateReachableRange';
import batchReachableRangeModel from '../../model/routing/batchCalculateReachableRange';
import modelResponse from '../../model/modelResponse';
import {
    batchRoutingEndpoint,
    calculateReachableRangeEndpoint,
    batchReachableRangeQueryEndpoint,
    batchSyncRoutingEndpoint
} from 'endpoints';

const fields = calculateReachableRangeParameters();
const maxNoOfElementsInBatchRequest = 100;

export function calculateReachableRange(core) {
    const singleRequest = core.singleRequestServiceFactory(calculateReachableRangeEndpoint);
    const batchRequest = core.batchRequestServiceFactory(maxNoOfElementsInBatchRequest, {
        batchSync: batchSyncRoutingEndpoint, batch: batchRoutingEndpoint,
        single: batchReachableRangeQueryEndpoint
    });

    const handleServiceCall = (data, abortSignal) => {
        return singleRequest(fields, data, abortSignal)
            .then(response => modelResponse(response, reachableRangeModel));
    };

    const handleBatchServiceCall = (data, abortSignal) => {
        return batchRequest(fields, data, abortSignal)
            .then(response => modelResponse(response, batchReachableRangeModel));
    };

    return core.serviceFactory(
        fields,
        SERVICE_TYPES.ROUTING,
        'calculateReachableRange',
        handleServiceCall,
        handleBatchServiceCall
    );
}
