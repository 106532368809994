import { additionalData } from './services/search/additionalData';
import { alongRouteSearch } from './services/search/alongRouteSearch';
import { categorySearch } from './services/search/categorySearch';
import { evChargingStationsAvailability } from './services/search/evChargingStationsAvailability';
import { fuzzySearch } from './services/search/fuzzySearch';
import { geometrySearch } from './services/search/geometrySearch';
import { nearbySearch } from './services/search/nearbySearch';
import { placeById } from './services/search/placeById';
import { poiCategories } from './services/search/poiCategories';
import { poiDetails } from './services/search/poiDetails';
import { poiPhotos } from './services/search/poiPhotos';
import { poiSearch } from './services/search/poiSearch';
import { autocomplete } from './services/search/autocomplete';
import { reverseGeocode } from './services/reverseGeocode/reverseGeocode';
import { geocode } from './services/geocode/geocode';
import { crossStreetLookup } from './services/reverseGeocode/crossStreetLookup';
import { structuredGeocode } from './services/structuredGeocode/structuredGeocode';

export default function searchServiceFactory(core) {
    return {
        autocomplete: autocomplete(core),
        additionalData: additionalData(core),
        alongRouteSearch: alongRouteSearch(core),
        evChargingStationsAvailability: evChargingStationsAvailability(core),
        fuzzySearch: fuzzySearch(core),
        geometrySearch: geometrySearch(core),
        nearbySearch: nearbySearch(core),
        categorySearch: categorySearch(core),
        placeById: placeById(core),
        poiCategories: poiCategories(core),
        poiDetails: poiDetails(core),
        poiPhotos: poiPhotos(core),
        poiSearch: poiSearch(core),
        reverseGeocode: reverseGeocode(core),
        structuredGeocode: structuredGeocode(core),
        crossStreetLookup: crossStreetLookup(core),
        geocode: geocode(core)
    };
}
