/**
 * Base class for all the Search services.
 *
 * @private
 * @class Search
 * @module Services
 * @uses ProtocolMixin
 * @uses KeyMixin
 * @uses LimitMixin
 * @uses LanguageMixin
 * @uses BatchMixin
 * @uses TimeZoneMixin
 * @uses RelatedPoisMixin
 *
 * @constructor
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 */
/* jshint nomen:false */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import utils from '../utils';
import searchModel from '../../model/search/search';
import batchModel from '../../model/search/batchModel';
import searchParams from './searchParameters';
import modelResponse from '../../model/modelResponse';
import {
    searchEndpoint,
    batchSearchQueryEndpoint,
    batchSyncSearchEndpoint,
    batchSearchEndpoint
} from 'endpoints';

class Search {
    constructor(options, additionalOptions) {
        this.options = options;
        this.additionalOptions = additionalOptions;
        this.defaultFields = utils.clone(searchParams.create());
        this.fields = {};
    }

    _getRestService = () => {
        return {
            search: this.core.singleRequestServiceFactory(searchEndpoint),
            batch: this.core.batchRequestServiceFactory(100, {
                single: batchSearchQueryEndpoint,
                batchSync: batchSyncSearchEndpoint,
                batch: batchSearchEndpoint
            })
        };
    }

    handleBatchServiceCall = (data, abortSignal) => {
        return this._getRestService().batch(this.fields, data, abortSignal)
            .then(response => modelResponse(response, batchModel));
    }

    handleServiceCall = (options, abortSignal) => {
        return this._getRestService().search(this.fields, options, abortSignal)
            .then(({ data, trackingId }) => {
                return modelResponse({
                    data: options.bestResult ? data.results[0] : data,
                    trackingId
                }, searchModel);
            });
    }

    construct = (serviceName, core) => {
        this.core = core;
        this.fields = utils.addFields(this.fields, this.defaultFields);
        const service = this.core.serviceFactory(
            this.fields,
            SERVICE_TYPES.SEARCH,
            serviceName || 'search',
            this.handleServiceCall,
            this.handleBatchServiceCall
        );

        return service(this.options, this.additionalOptions);
    }
}

export default Search;
