/**
 * Makes a search request using the TomTom
 * [Search API - Category Search](CATEGORY_SEARCH_URL).
 *
 * Parameters need to be passed to the constructor.
 *
 * The list of all supported category codes are available
 * [here](SEARCH_API_SUPPORTED_CATEGORY_CODES_URL).
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(result) {
 *   console.log(result);
 * };
 *
 * tt.services.categorySearch({
 *    key: <Your API key>,
 *    query: 'restaurant'
 * }).then(callbackFn);
 * ```
 * @class categorySearch
 * @namespace Services.services
 * @extends Search
 * @uses ExtendedPostalCodesForMixin
 * @uses CenterMixin
 * @uses RadiusMixin
 * @uses BoundingBoxMixin
 * @uses CountrySetMixin
 * @uses OffsetMixin
 * @uses BestResultMixin
 * @uses QueryMixin
 * @uses TypeaheadMixin
 * @uses ViewMixin
 * @uses BrandSetMixin
 * @uses OpeningHoursMixin
 * @uses CategorySetMixin
 * @uses ConnectorSetMixin
 * @uses MapcodesMixin
 * @uses FuelSetMixin
 * @uses MinPowerKwMixin
 * @uses MaxPowerKwMixin
 * @uses AbortSignalMixin
 * @constructor
 * @module Services
 *
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 *
 */
import Search from './search';
import { boundingBox } from './searchParameters';
import { CATEGORY_SEARCH } from '../../common/searchTypes';

class CategorySearch extends Search {
    constructor(options, additionalOptions) {
        super(options, additionalOptions);

        this.fields.type = {
            defaultValue: CATEGORY_SEARCH,
            visible: false
        };

        this.fields.boundingBox = boundingBox();
    }
}

export function categorySearch(core) {
    return (options, additionalOptions) =>
        (new CategorySearch(options, additionalOptions)).construct('categorySearch', core);
}
