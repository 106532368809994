import { convertResponsePoints } from '../modelUtils';

export default (data) => {
    if (!data) {
        return data;
    }
    // converts location points
    if (Array.isArray(data.results)) {
        data.results.forEach(result => {
            if (Array.isArray(result)) {
                result.forEach(r => {
                    convertResponsePoints(r);
                });
            } else {
                convertResponsePoints(result);
            }
        });
    }

    convertResponsePoints(data);

    return data;
};
