function newFeature(route) {
    return {
        type: 'Feature',
        properties: {summary: route.summary, sections: route.sections, segmentSummary: []}
    };
}

function getCoordinates(points) {
    const coordinates = [];
    for (let i = 0; i < points.length; i += 1) {
        coordinates.push([points[i].lng, points[i].lat]);
    }
    return coordinates;
}

export default (route) => {
    const isMultiLineString = route.legs.length > 1;

    const feature = newFeature(route);
    for (let i = 0; i < route.legs.length; i += 1) {
        feature.properties.segmentSummary.push(route.legs[i].summary);

        if (route.legs[i].points) {
            feature.geometry = feature.geometry || {
                coordinates: []
            };

            if (isMultiLineString) {
                feature.geometry.type = 'MultiLineString';
                feature.geometry.coordinates.push(getCoordinates(route.legs[i].points));
            } else {
                feature.geometry.type = 'LineString';
                feature.geometry.coordinates = getCoordinates(route.legs[i].points);
            }
        }
    }
    if (route.guidance) {
        feature.properties.guidance = route.guidance;
    }
    return feature;
};
