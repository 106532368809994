import { isString, isEmpty } from 'lodash';
import utils from './utils';

const engineType = {
    combustion: 'combustion',
    electric: 'electric',
    any: 'any'
};

function validateIfPointsGreaterOrEqual(speedsAndConsumptions) {
    const sortedKeys = Object.keys(speedsAndConsumptions).sort(function(x, y) {
        return parseFloat(x) > parseFloat(y);
    });
    const keysLength = sortedKeys.length;

    if (speedsAndConsumptions[sortedKeys[keysLength - 2]] > speedsAndConsumptions[sortedKeys[keysLength - 1]]) {
        throw new Error('Consumption for two highest speeds should be increasing');
    }
}

function validateFloat(val) {
    if (isNaN(parseFloat(val)) || !isFinite(val)) {
        throw new Error('A value parsable to float is expected, but ' + val + ' [' + typeof val + '] given');
    }
}

/**
 * @param {String[]} arr
 */
function consumptionPairsValidator(arr) {
    const speedsAndConsumptions = {};
    arr.forEach(function(element) {
        const pair = element.split(',');
        if (pair.length !== 2) {
            throw new Error('Invalid number of parameters in the pair around ' + element);
        }
        if (pair[0].trim().length === 0) {
            throw new Error('Speed value must not be empty.');
        }
        if (pair[1].trim().length === 0) {
            throw new Error('Consumption value must not be empty.');
        }
        validateFloat(pair[0]);
        validateFloat(pair[1]);
        if (Object.prototype.hasOwnProperty.call(speedsAndConsumptions, parseFloat(pair[0]))) {
            throw new Error('Duplicate speed: ' + pair[0]);
        } else {
            speedsAndConsumptions[parseFloat(pair[0])] = parseFloat(pair[1]);
        }
    });
    if (Object.keys(speedsAndConsumptions).length > 1) {
        validateIfPointsGreaterOrEqual(speedsAndConsumptions);
    }
}

function checkIfCorrectEngineType(obj, type) {
    if (obj.vehicleEngineType && obj.vehicleEngineType !== type && type !== engineType.any) {
        throw new Error('Expecting vehicleEngineType set to ' + type);
    }
}

function efficiencyParameterRequired(obj, paramName) {
    if (!(utils.isValidValue(obj.accelerationEfficiency) &&
        utils.isValidValue(obj.decelerationEfficiency) &&
        utils.isValidValue(obj.uphillEfficiency) &&
        utils.isValidValue(obj.downhillEfficiency))) {
        throw new Error('Efficiency parameters are required when using ' + paramName);
    }
}

function hasEfficiencyParameterSet(obj) {
    return obj.accelerationEfficiency ||
        obj.decelerationEfficiency ||
        obj.uphillEfficiency ||
        obj.downhillEfficiency;
}

function validateDependantParameters(obj, paramName1, paramName2) {
    if (!(obj[paramName1] && obj[paramName2])) {
        throw new Error('Missing dependant parameter. Expecting both defined: ' + paramName1 + ', ' + paramName2);
    }
}

function validateEfficiencyParameters(obj, paramName1, paramName2) {
    if (obj[paramName1] * obj[paramName2] > 1) {
        throw new Error('Product of ' + paramName1 + ' and ' + paramName2 + ' cannot exceed 1');
    }
}

/**
 * @ignore
 * Consumption Model cannot be used with travelMode values bicycle and pedestrian.
 * @param {Object} obj
 */
function validateTravelMode(obj) {
    if (obj.travelMode === 'bicycle' || obj.travelMode === 'pedestrian') {
        throw new Error('Consumption model parameters cannot be set if travelMode is set to bicycle or pedestrian');
    }
}

/**
 * @ignore
 * All parameters require constantSpeedConsumption* to be specified by the user. It is an
 * error to specify any other consumption model parameter (with the exception of vehicleWeight)
 * if constantSpeedConsumption* is not specified.
 * @param {Object} obj
 */
function hasConstantSpeedConsumption(obj) {
    if (!obj.constantSpeedConsumptionInLitersPerHundredkm && !obj.constantSpeedConsumptionInkWhPerHundredkm) {
        throw new Error('Consumption model cannot be used without setting constant speed consumption parameter');
    }
}

export default {
    /**
     * @ignore
     * The list must contain between 1 and 25 points (inclusive), and may not contain
     * duplicate points for the same speed. If it only contains a single point, then the
     * consumption rate of that point is used without further processing.
     * Consumption specified for the highest speed must be greater than or equal to
     * that of the penultimate highest speed. This ensures that extrapolation does not
     * lead to negative consumption rates. Similarly, consumption values specified for the
     * two lowest speeds in the list cannot lead to a negative consumption rate for any lower speed.
     * The minimum and maximum values described here refer to the valid range for the consumption
     * values (expressed in l/100km).
     * @param {Number} engineType
     * @param {Object} obj
     * @return {Function} function
     */
    constantSpeedConsumption: (engineType) => {
        return (value, obj) => {
            if (value === undefined || value === null) {
                return;
            }
            validateTravelMode(obj);
            checkIfCorrectEngineType(obj, engineType);
            if (!isString(value)) {
                throw new TypeError('Expecting a String like "15.2,12.2:8.0,9.0"');
            }
            const speedPairs = value.split(':');
            if (speedPairs.length < 1 || speedPairs.length > 25) {
                throw new Error('Incorrect amount of speed-consumption pairs provided. Expecting 1-25, but got ' +
                    value.length);
            }
            consumptionPairsValidator(speedPairs);
        };
    },
    vehicleWeight: function(value, obj) {
        if (hasEfficiencyParameterSet(obj) && value === undefined) {
            throw new Error('vehicleWeight parameter must be set if any efficiency parameters is present');
        }
    },
    floatAndEngineType: function(engineType, paramName) {
        return function(value, obj) {
            if (!value) {
                return;
            }
            validateTravelMode(obj);
            hasConstantSpeedConsumption(obj);
            checkIfCorrectEngineType(obj, engineType);
            validateFloat(value);
            if (value < 0) {
                throw new Error(paramName + ': Expecting positive value');
            }
        };
    },
    fuelEnergyDensityInMJoulesPerLiter: function(value, obj) {
        if (!value) {
            return;
        }
        validateTravelMode(obj);
        validateFloat(value);
        hasConstantSpeedConsumption(obj);
        checkIfCorrectEngineType(obj, 'combustion');
        efficiencyParameterRequired(obj, 'fuelEnergyDensityInMJoulesPerLiter');
    },
    /**
     * @ignore
     * accelerationEfficiency and decelerationEfficiency must always be specified as a pair (i.e., both or none).
     * uphillEfficiency and downhillEfficiency must always be specified as a pair (i.e., both or none).
     * If accelerationEfficiency and decelerationEfficiency are specified, the
     * product of their values must not be greater than 1 (to prevent perpetual motion).
     * If uphillEfficiency and downhillEfficiency are specified, the product of their
     * values must not be greater than 1 (to prevent perpetual motion).
     * @param {Number} paramName1
     * @param {Number} paramName2
     * @return {undefined} undefined
     */
    efficiencyParameter: function(paramName1, paramName2) {
        return function(value, obj) {
            if (!value) {
                return;
            }
            validateTravelMode(obj);
            hasConstantSpeedConsumption(obj);
            checkIfCorrectEngineType(obj, engineType.any);
            validateDependantParameters(obj, paramName1, paramName2);
            /**
             * @ignore
             * If *Efficiency parameters are specified by the user, then vehicleWeight must also be specified.
             */
            validateDependantParameters(obj, paramName1, 'vehicleWeight');
            /**
             * @ignore
             * When vehicleEngineType is combustion, fuelEnergyDensityInMJoulesPerLiter must be specified as well.
             */
            if (obj.vehicleEngineType === engineType.combustion) {
                validateDependantParameters(obj, paramName1, 'fuelEnergyDensityInMJoulesPerLiter');
            }
            validateEfficiencyParameters(obj, paramName1, paramName2);
            validateFloat(value);
        };
    },
    chargeParameter: function(paramName1, paramName2) {
        return function(value, obj) {
            if (!value) {
                return;
            }
            validateTravelMode(obj);
            hasConstantSpeedConsumption(obj);
            checkIfCorrectEngineType(obj, engineType.electric);
            validateDependantParameters(obj, paramName1, paramName2);
            validateFloat(value);
        };
    },
    budgetInRange: function(_, data) {
        let maxBudget;
        let currentBudgetProvided;
        const electricEngine = data.vehicleEngineType === 'electric';
        if (electricEngine) {
            maxBudget = data.currentChargeInkWh;
            currentBudgetProvided = data.energyBudgetInkWh;
            if (currentBudgetProvided > maxBudget) {
                throw new Error('Energy budget may not be greater than current energy.');
            }
        } else {
            maxBudget = data.currentFuelInLiters;
            currentBudgetProvided = data.fuelBudgetInLiters;
            if (currentBudgetProvided > maxBudget) {
                throw new Error('Fuel budget may not be greater than current fuel.');
            }
        }
        if (currentBudgetProvided < 0) {
            throw new Error('Budget may not be negative.');
        }
    },
    requiredBudget: function(_, data) {
        const liters = 'fuelBudgetInLiters' in data;
        const watts = 'energyBudgetInkWh' in data;
        const seconds = 'timeBudgetInSec' in data;
        const meters = 'distanceBudgetInMeters' in data;
        const numberOfSetParams = [liters, watts, seconds, meters].filter(budget => budget).length;
        if (numberOfSetParams === 0 || numberOfSetParams > 1) {
            throw new Error('Exactly one of fuelBudgetInLiters, energyBudgetInkWh, ' +
                'timeBudgetInSec and distanceBudgetInMeters must be set.');
        }
    },
    requiredWithSpecificEngineType: function(value, data, key) {
        const consumptionInLiters = 'constantSpeedConsumptionInLitersPerHundredkm' in data;
        const consumptionInWatts = 'constantSpeedConsumptionInkWhPerHundredkm' in data;
        const electricEngine = data.vehicleEngineType === 'electric';

        if (key === 'energyBudgetInkWh' && value) {
            if (!electricEngine) {
                throw new Error('Engine type should be "electric" when energyBudgetInkWh is set');
            }
            if (!consumptionInWatts) {
                throw new Error('Missing constant speed consumption for electric engine.');
            }
        } else if (key === 'fuelBudgetInLiters' && value) {
            if (electricEngine) {
                throw new Error('Engine type should be "combustion" or undefined when fuelBudgetInLiters is set');
            }
            if (!consumptionInLiters) {
                throw new Error('Missing constant speed consumption for combustion engine.');
            }
        }
    },
    notCommon: function(_, data) {
        const forbiddenAvoid = 'alreadyUsedRoads';
        const forbiddenModes = ['bicycle', 'pedestrian'];

        if ('avoid' in data && data.avoid.indexOf(forbiddenAvoid) > -1) {
            throw new Error(forbiddenAvoid + ' is not allowed value for avoid parameter ' +
                'in Calculate Reachable Route request.');
        }

        if ('travelMode' in data && forbiddenModes.indexOf(data.travelMode) > -1) {
            throw new Error(data.travelMode + ' is not allowed value for travelMode parameter ' +
                'in Calculate Reachable Route request.');
        }

        if ('arriveAt' in data) {
            throw new Error('arriveAt parameter is not allowed in Calculate Reachable Route request.');
        }
    },
    firstParamCannotBeUsedWithSecond: function(paramNameUsed, paramNameToAvoid) {
        return function(value, obj) {
            if (!value) {
                return;
            }
            if (Object.prototype.hasOwnProperty.call(obj, paramNameToAvoid) &&
                utils.isValidValue(obj[paramNameToAvoid])) {
                throw new Error(paramNameUsed + ' parameter cannot be used in conjunction with ' +
                    paramNameToAvoid);
            }
        };
    },
    requiresDependantParameter: function(parameterName, dependantParameter) {
        return function(value, obj) {
            if (!value) {
                return;
            }
            if (!Object.prototype.hasOwnProperty.call(obj, dependantParameter)) {
                throw new Error(dependantParameter + ' must be specified when using with ' + parameterName);
            }
        };
    },
    notRequiredWithCategoryNorBrandSet: function(_, data) {
        const isQueryAbsent = isEmpty(data.query);
        const isBrandSetAbsent = isEmpty(data.brandSet);
        const isCategorySetAbsent = isEmpty(data.categorySet);
        if (isQueryAbsent && isBrandSetAbsent && isCategorySetAbsent) {
            throw new Error('Empty query parameter is only allowed when used with brandSet or categorySet filters');
        }
    },
    consumptionRecuperationAltitudeParameter: function(_, data) {
        const recuperationInkWhPerkmAltitudeLoss = data.recuperationInkWhPerkmAltitudeLoss;
        const consumptionInkWhPerkmAltitudeGain = data.consumptionInkWhPerkmAltitudeGain;

        validateFloat(recuperationInkWhPerkmAltitudeLoss);
        validateFloat(consumptionInkWhPerkmAltitudeGain);

        if (consumptionInkWhPerkmAltitudeGain < recuperationInkWhPerkmAltitudeLoss) {
            throw new Error('consumptionInkWhPerkmAltitudeGain must be greater than ' +
            'recuperationInkWhPerkmAltitudeLoss');
        }

        if (consumptionInkWhPerkmAltitudeGain > 500) {
            throw new Error('recuperationInkWhPerkmAltitudeLoss and less than 500.0');
        }

        if (recuperationInkWhPerkmAltitudeLoss < 0) {
            throw new Error('recuperationInkWhPerkmAltitudeLoss must be greater than 0.0');
        }
    }
};
