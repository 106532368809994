
import { staticImage } from './services/staticImage/staticImage';
import { copyrights } from './services/copyrights/copyrights';
import { copyrightsCaption } from './services/copyrights/copyrightsCaption';

export default function mapDisplayServiceFactory(core) {
    return {
        staticImage: staticImage(core),
        copyrights: copyrights(core),
        copyrightsCaption: copyrightsCaption(core)
    };
}
