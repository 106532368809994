import { convertResponsePoints } from '../modelUtils';

function convertEntryPoints(element) {
    if (element && element.entryPoints && Array.isArray(element.entryPoints)) {
        element.entryPoints.forEach(entryPoint => {
            convertResponsePoints(entryPoint);
        });
    }
}

export default (data) => {
    if (!data) {
        return data;
    }
    // converts location points
    if (Array.isArray(data.results)) {
        data.results.forEach(result => {
            if (Array.isArray(result)) {
                result.forEach(r => {
                    convertResponsePoints(r);
                    convertEntryPoints(r);
                });
            } else {
                convertResponsePoints(result);
                convertEntryPoints(result);
            }
        });
    } else {
        convertEntryPoints(data);
    }

    convertResponsePoints(data);

    return data;
};
