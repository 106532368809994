import * as validators from '../validators';
import postValidators from '../postValidators';
import converters from '../converters';
import getRoutingParameters from './routingParameters';
import parameterApplications from '../../common/parameterApplications';
import utils from '../utils';

/**
 * @private
 * @class LongDistanceEVRoutingParameters
 * @module Services
 */

const fieldsToSkip = ['arriveAt', 'travelMode', 'computeTravelTimeFor', 'alternativeType',
    'maxAlternatives', 'instructionsType', 'language', 'computeBestOrder', 'routeRepresentation',
    'supportingPoints', 'minDeviationDistance', 'minDeviationTime', 'constantSpeedConsumptionInLitersPerHundredkm',
    'currentFuelInLiters', 'fuelEnergyDensityInMJoulesPerLiter', 'hilliness', 'windingness', 'routeType',
    'vehicleEngineType', 'currentChargeInkWh', 'maxChargeInkWh', 'constantSpeedConsumptionInkWhPerHundredkm'];

let fields = getRoutingParameters(fieldsToSkip);

const extendedFields = {
    /* eslint-disable max-len */
    /**
     * Specifies the efficiency of converting chemical energy stored in fuel to kinetic energy when the
     * vehicle accelerates (i.e., KineticEnergyGained/ChemicalEnergyConsumed).
     * ChemicalEnergyConsumed is obtained by converting consumed fuel to chemical energy using
     * fuelEnergyDensityInMJoulesPerLiter.
     * @attribute accelerationEfficiency
     * @param {Number} [options.accelerationEfficiency]
     */
    /**
     * List of 3-character ISO 3166-1 alpha-3 country codes of countries in which
     * all toll roads with vignettes are to be avoided.
     * Toll roads with vignettes in countries not in the list are unaffected.
     * It is an error to specify both avoidVignette and allowVignette.
     * @attribute avoidVignette
     * @param {String|String[]} [options.avoidVignette] An array of country codes.
     * @throws {TypeError} If the given argument cannot be converted into a valid value.
     */
    /**
     * Specifies the amount of power consumed for sustaining auxiliary systems in kilowatts (kW).
     * It can be used to specify consumption due to devices and systems such as AC systems, radio, heating, etc.
     * @attribute auxiliaryPowerInkW
     * @param {Number} [options.auxiliaryPowerInkW]
     */
    auxiliaryPowerInkW: {
        validators: [
            postValidators.floatAndEngineType('electric', 'auxiliaryPowerInkW')
        ]
    },
    /**
     * Specifies something that the route calculation should try to avoid when determining the route. Can be
     * specified multiple times. Possible values:
     * *    `tollRoads`: avoids toll roads.
     * *    `motorways`: avoids motorways.
     * *    `ferries`: avoids ferries.
     * *    `unpavedRoads`: avoids unpaved roads.
     * *    `carpools`: avoids routes that require use of a carpool (HOV/ High Occupancy Vehicle) lanes.
     * *    `alreadyUsedRoads`: avoids using the same road multiple times.
     *
     * Most useful in conjunction with `routeType=thrilling`.
     *
     * @attribute avoid
     * @param {Array|String} [options.avoid] Specifies something that the route calculation should try to avoid when
     *     determining the route.
     */
    /**
     * Supported shapes include rectangles.
     * Can contain one of each supported shapes element.

     * @example
     * ```js
     * tt.services.calculateRoute({
     *      locations: '52.37173,4.91015:52.36357,4.898046255',
     *      avoidAreas: [{
     *      southWestCorner: {latitude: 52.36391, longitude:4.90106},
     *      northEastCorner: {latitude: 52.36554, longitude:4.90767}
     * }]);
     * ```
     * Supported point formats:
     * - 'lat,lon'
     * - {lat:111, lon:111}
     * - {lat:111, lng:111}
     * - {latitude:111, longitude:111}
     * - {x:111, y:111}
     * - {lat: function(), lng:function()}
     *
     * @attribute avoidAreas
     * @param {Object[]} [options.avoidAreas] A list of shapes to avoid for planning routes.
     * @throws {TypeError} If the given argument cannot be converted to a valid value.
     */
    /**
     * List of 3-character ISO 3166-1 alpha-3 country codes of countries in which
     * all toll roads with vignettes are to be avoided.
     * Toll roads with vignettes in countries not in the list are unaffected.
     * It is an error to specify both avoidVignette and allowVignette.
     * @attribute avoidVignette
     * @param {String|String[]} [options.avoidVignette] An array of country codes.
     * @throws {TypeError} If the given argument cannot be converted into a valid value.
     */
    /**
     * Specifies the speed-dependent component of consumption.
     * Provided as an unordered list of speed/consumption-rate pairs. The list defines points
     * on a consumption curve.
     * Consumption rates for speeds not in the list are found as follows:
     * *    By linear interpolation, if the given speed lies in between two speeds in the list.
     * *    By linear extrapolation otherwise, assuming a constant (ΔConsumption/ΔSpeed) determined by
     * the nearest two points in the list.
     *
     * The list must contain between 1 and 25 points (inclusive), and may not contain duplicate points
     * for the same speed. If it only contains a single point, then the consumption rate of that point
     * is used without further processing. Consumption specified for the largest speed must be greater
     * than or equal to that of the penultimate highest speed.
     * This ensures that extrapolation does not lead to negative consumption rates.
     * Similarly, consumption values specified for the two lowest speeds in the list cannot lead to a
     * negative consumption rate for any lower speed.
     * The minimum and maximum values described here refer to the valid range for the consumption values
     * (expressed in kWh/100km).
     * @attribute constantSpeedConsumptionInkWhPerHundredkm
     * @param {Array} options.constantSpeedConsumptionInkWhPerHundredkm Colon-delimited list of
     * ElectricConstantSpeedConsumptionPairs, e.g., an array of such pairs "60,7".
     */
    constantSpeedConsumptionInkWhPerHundredkm: {
        validators: [
            postValidators.constantSpeedConsumption('electric')
        ],
        converters: [converters.constantSpeedConsumption],
        required: true
    },
    /**
     * Specifies the current electric energy supply in kilowatt hours (kWh).
     * @attribute currentChargeInkWh
     * @param {Number} options.currentChargeInkWh
     */
    currentChargeInkWh: {
        validators: [
            postValidators.chargeParameter('currentChargeInkWh', 'maxChargeInkWh')
        ],
        required: true
    },
    /**
     * Specifies the efficiency of converting kinetic energy to saved (not consumed) fuel when the
     * vehicle decelerates (i.e., ChemicalEnergySaved/KineticEnergyLost).
     * ChemicalEnergySaved is obtained by converting saved (not consumed) fuel to energy using
     * fuelEnergyDensityInMJoulesPerLiter.
     * @attribute decelerationEfficiency
     * @param {Number} [options.decelerationEfficiency]
     */
    /**
     * The date and time of departure from the origin point.
     * Departure times, apart from now, must be specified as a dateTime.
     * When a time zone offset is not specified, it will be assumed to be that of the origin point.
     * The departAt value must be in the future. The departAt parameter cannot be used in conjunction with
     * arriveAt.
     *
     * @attribute departAt
     * @param {String} [options.departAt] The properly formatted date with the time or 'now' value. Date
     * cannot be in the past and has to follow ISO 8601 standard.
     */
    /**
     * Specifies the efficiency of converting potential energy to saved (not consumed) fuel when the
     * vehicle loses elevation (i.e., ChemicalEnergySaved/PotentialEnergyLost).
     * ChemicalEnergySaved is obtained by converting saved (not consumed) fuel to energy using
     * fuelEnergyDensityInMJoulesPerLiter.
     * @attribute downhillEfficiency
     * @param {Number} [options.downhillEfficiency]
     */
    /**
     * Specifies the maximum electric energy supply in kilowatt hours (kWh) that may be stored in
     * the vehicle's battery.
     * @attribute maxChargeInkWh
     * @param {Number} options.maxChargeInkWh
     */
    maxChargeInkWh: {
        validators: [
            postValidators.chargeParameter('maxChargeInkWh', 'currentChargeInkWh')
        ],
        required: true
    },
    /**
     * Represents the type of protocol used to perform the service call.
     *
     * @attribute protocol
     * @param {String} [options.protocol] Protocol type, one of `http` or `https`.
     */
    /**
     * Specifies which data should be reported for diagnosis purposes.
     *
     * Possible values: _effectiveSettings_
     * Reports the effective parameters or data used when calling the API.
     *
     * In the case of defaulted parameters, the default will be reflected where the parameter was not specified
     * by the caller.
     *
     * @attribute report
     * @param {String} [options.report] The level of a verbose answer from the service, useful for debugging purposes.
     */
    /**
     * Specifies which of the section types is reported in the route response.
     *
     * Possible values:
     * - `carTrain`, `ferry`, `tunnel` or `motorway`: get
     * sections if the route includes car trains, ferries, tunnels, or motorways.
     * - pedestrian: sections which are only suited for pedestrians.
     * - tollRoad: sections which require a toll to be payed.
     * - tollVignette: sections which require a toll vignette to be present.
     * - country: countries the route has parts in.
     * - travelMode: sections in relation to the request parameter 'travelMode'.
     *
     * The default value is travelMode.
     * @attribute sectionType
     * @param {String|String[]} [options.sectionType] An array of section types.
     */
    /**
     * Possible values:
     * - `true` (do consider all available traffic information during routing)
     * - `false` (ignores current traffic data during routing) Note that although the current traffic data is
     *   ignored during routing, the effect of historic traffic on effective road speeds is still incorporated.
     *
     * The default is true.
     *
     * @attribute traffic
     * @param {Boolean} [options.traffic=true] A boolean flag determines if traffic data should be used to calculate the
     *     route.
     */
    /**
     * Weight per axle of the vehicle in kg (kilograms). A value of 0 means that weight restrictions per axle are not
     * considered during route planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleAxleWeight
     * @param {Number} [options.vehicleAxleWeight] The vehicle axle weight in kg (kilograms).
     */
    /**
     * The vehicle is used for commercial purposes and thus may not be allowed to drive on some roads. This
     * parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleCommercial
     * @param {Boolean} [options.vehicleCommercial] True if the vehicle is used for commercial purposes.
     */
    /**
     * Engine type of the vehicle. This parameter is currently only considered for travelMode truck. Valid
     * options are: `electric`
     *
     * @attribute vehicleEngineType
     * @param {String} options.vehicleEngineType The vehicle engine type.
     */
    vehicleEngineType: {
        validators: [validators.oneOfValue(['electric'], 'vehicleEngineType')],
        required: true
    },
    /**
     * The directional heading of the vehicle in degrees starting at true North and continuing in a clockwise
     * direction. North is 0 degrees, East is 90 degrees, South is 180 degrees, West is 270 degrees. Possible
     * values are 0-359.
     *
     * @attribute vehicleHeading
     * @param {Number} [options.vehicleHeading] The angle in which the vehicle is heading.
     */
    /**
     * Height of the vehicle in meters. A value of 0 means that height restrictions are not considered
     * during route planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleHeight
     * @param {Number} [options.vehicleHeight] The vehicle height in meters.
     */
    /**
     * Length of the vehicle in meters. A value of 0 means that length restrictions are not considered during
     * route planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleLength
     * @param {Number} [options.vehicleLength] The vehicle length in meters.
     */
    /**
     * Types of cargo that may be classified as hazardous materials and restricted from some roads. Available
     * vehicleLoadType values are US Hazmat classes 1 through 9, plus generic classifications for use in other
     * countries.
     *
     * __Use these for routing in US__
     * * `USHazmatClass1 Explosives`
     * * `USHazmatClass2 Compressed gas`
     * * `USHazmatClass3 Flammable liquids`
     * * `USHazmatClass4 Flammable solids`
     * * `USHazmatClass5 Oxidizers`
     * * `USHazmatClass6 Poisons`
     * * `USHazmatClass7 Radioactive`
     * * `USHazmatclass8 Corrosives`
     * * `USHazmatClass9 Miscellaneous`
     *
     * __Use these for routing in all other countries__
     * * `otherHazmatExplosive Explosives`
     * * `otherHazmatGeneral Miscellaneous`
     * * `otherHazmatHarmfulToWater Harmful to water`
     *
     * This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleLoadType
     * @param {Array|String} [options.vehicleLoadType] The array of truck cargo classification.
     */
    /**
     * Maximum speed of the vehicle in km/hour. A value of 0 means that an appropriate value for the vehicle
     * will be determined and applied during route planning. A non-zero value may be overridden during route
     * planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleMaxSpeed
     * @param {Number} [options.vehicleMaxSpeed] The maximal allowed vehicle speed in km/h.
     */
    /**
     * Weight of the vehicle in kg. A value of 0 means that weight restrictions are not considered during route
     * planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleWeight
     * @param {Number} [options.vehicleWeight] The vehicle weight in kg (kilograms).
     */
    /**
     * Width of the vehicle in meters. A value of 0 means that width restrictions are not considered during
     * route planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleWidth
     * @param {Number} [options.vehicleWidth] The vehicle width in meters.
     */
    /**
     * Specifies the efficiency of converting chemical energy stored in fuel to potential energy
     * when the vehicle gains elevation (i.e., PotentialEnergyGained/ChemicalEnergyConsumed).
     * ChemicalEnergyConsumed is obtained by converting consumed fuel to chemical energy using
     * fuelEnergyDensityInMJoulesPerLiter.
     * @attribute uphillEfficiency
     * @param {Number} [options.uphillEfficiency]
     */
    /**
     * @attribute chargingModes
     * @param {Array} options.chargingModes A non-empty list of at most 10 `chargingModes`, which the vehicle is
     * able to use to charge. Each `chargingConnection` appears in at most one `chargingMode`.
     */
    chargingModes: {
        validators: [validators.chargingModes],
        required: true,
        application: parameterApplications.POST
    },
    /**
     * @attribute minChargeAtDestinationInkWh
     * @param {Number} options.minChargeAtDestinationInkWh The battery level upon arrival at the destination
     * of the resulting route will be at least this much.
     * *Maximum value:* `maxChargeInkWh`
     */
    minChargeAtDestinationInkWh: {
        validators: [validators.numberInInterval(0, Number.MAX_VALUE)],
        required: true
    },
    /**
     * @attribute minChargeAtChargingStopsInkWh
     * @param {Number} options.minChargeAtChargingStopsInkWh The battery level upon arrival at each intermediate
     * charging stop of the resulting route will be at least this much.
     * *Maximum value:* `0.2 × maxChargeInkWh`
     */
    minChargeAtChargingStopsInkWh: {
        validators: [validators.numberInInterval(0, Number.MAX_VALUE)],
        required: true
    },
    /**
     * Specifies the electric energy in kWh gained by the vehicle through losing 1000 meters of elevation.
     * * Note: It must be paired with `consumptionInkWhPerkmAltitudeGain`.
     * * Note: It cannot be used with `accelerationEfficiency`, `decelerationEfficiency`, `uphillEfficiency`
     * or `downhillEfficiency`.
     *
     * Minimum value: 0.0
     * Maximum value: `consumptionInkWhPerkmAltitudeGain`
     *
     * @attribute recuperationInkWhPerkmAltitudeLoss
     * @param {Number} [options.recuperationInkWhPerkmAltitudeLoss] Electric energy in kWh
     */
    recuperationInkWhPerkmAltitudeLoss: {
        validators: [
            postValidators.requiresDependantParameter(
                'recuperationInkWhPerkmAltitudeLoss',
                'consumptionInkWhPerkmAltitudeGain'
            ),
            postValidators.consumptionRecuperationAltitudeParameter
        ]
    },

    /**
     * Specifies the electric energy in kWh consumed by the vehicle through gaining 1000 meters of elevation.
     * * Note: It must be paired with `recuperationInkWhPerkmAltitudeLoss`.
     * * Note: It cannot be used with `accelerationEfficiency`, `decelerationEfficiency`, `uphillEfficiency`
     * or `downhillEfficiency`.
     *
     * Minimum value: `vehicleAdrTunnelRestrictionCode`
     * Maximum value: 500.0
     *
     * @attribute consumptionInkWhPerkmAltitudeGain
     * @param {Number} [options.consumptionInkWhPerkmAltitudeGain] Electric energy in kWh
     */
    consumptionInkWhPerkmAltitudeGain: {
        validators: [
            postValidators.requiresDependantParameter(
                'consumptionInkWhPerkmAltitudeGain',
                'recuperationInkWhPerkmAltitudeLoss'
            ),
            postValidators.consumptionRecuperationAltitudeParameter
        ]
    },

    /**
     * If specified, the vehicle is subject to ADR tunnel restrictions.
     * * Vehicles with code B are restricted from roads with ADR tunnel categories B, C, D, and E.
     * * Vehicles with code C are restricted from roads with ADR tunnel categories C, D, and E.
     * * Vehicles with code D are restricted from roads with ADR tunnel categories D and E.
     * * Vehicles with code E are restricted from roads with ADR tunnel category E.
     * * If `vehicleAdrTunnelRestrictionCode` is not specified, no ADR tunnel restrictions apply.
     *
     * Notes:
     * * If `travelMode` is pedestrian or bicycle, `vehicleAdrTunnelRestrictionCode` is not considered.
     * * The `vehicleAdrTunnelRestrictionCode` and `vehicleLoadType` parameters are independent; please
     * provide both if applicable.
     *
     * @attribute vehicleAdrTunnelRestrictionCode
     * @param {String} [options.vehicleAdrTunnelRestrictionCode] An ADR restriction code
     * @throws {TypeError} If the given argument is not a valid ADR restriction code.
     */
    vehicleAdrTunnelRestrictionCode: {
        validators: [validators.oneOfValue(['B', 'C', 'D', 'E'], 'vehicleAdrTunnelRestrictionCode')]
    },

    /**
     * The travel mode for the requested route.
     * The only possible value is `car`.
     *
     * @attribute travelMode
     * @param {String} [options.travelMode] The primary means of transportation to be used while routing.
     */
    travelMode: {
        validators: [validators.oneOfValue(['car'], 'travel mode')]
    }
};

fields = utils.extend(fields, extendedFields);

export default () => {
    return utils.extend(fields, extendedFields);
};
