import * as validators from '../validators';
import converters from '../converters';
import postValidators from '../postValidators';
import parameterApplications from 'common/parameterApplications';

/**
 * @private
 * @class CommonRoutingParameters
 * @module Services
 * @uses KeyMixin
 */

const fields = {
    key: {
        validators: [validators.key]
    },

    /**
     * The type of route requested. Notes on specific values:
     * *    fastest returns the fastest route.
     * *    shortest returns the shortest route by distance.
     * *    eco routes balance economy and speed.
     * *    thrilling routes include interesting or challenging roads and use as few motorways as possible.
     * You can choose the level of turns included and also the degree of hilliness. See the hilliness and
     * windingness parameters to see how to set this. There is a limit of 900km on routes planned with
     * routeType=thrilling.
     *
     * The default value is fastest.
     *
     * @attribute routeType
     * @param {String} [options.routeType='fastest'] The route type to be used during route calculation.
     */
    routeType: {
        validators: [validators.oneOfValue(['fastest', 'shortest', 'eco', 'thrilling'], 'route type')]
    },

    /**
     * Possible values:
     * - true (do consider all available traffic information during routing)
     * - false (ignores current traffic data during routing) Note that although the current traffic data is
     *   ignored during routing, the effect of historic traffic on effective road speeds is still incorporated.
     *
     * The default is true.
     *
     * @attribute traffic
     * @param {Boolean} [options.traffic=true] A boolean flag determines if traffic data should be used to calculate the
     *     route.
     */
    traffic: {
        validators: [validators.bool]
    },

    /**
     * Specifies something that the route calculation should try to avoid when determining the route. Can be
     * specified multiple times. Possible values:
     * *    tollRoads: avoids toll roads.
     * *    motorways: avoids motorways.
     * *    ferries: avoids ferries.
     * *    unpavedRoads: avoids unpaved roads.
     * *    carpools: avoids routes that require use of a carpool (HOV/ High Occupancy Vehicle) lanes.
     * *    alreadyUsedRoads: avoids using the same road multiple times.
     *
     * Most useful in conjunction with routeType=thrilling.
     *
     * @attribute avoid
     * @param {Array|String} [options.avoid] Specifies something that the route calculation should try to avoid when
     *     determining the route.
     */
    avoid: {
        validators: [validators.arrayOfValues(['tollRoads', 'motorways', 'ferries', 'unpavedRoads', 'carpools',
            'alreadyUsedRoads'
        ], 'avoid')],
        converters: [converters.arrayOfStrings]
    },

    /**
     * The date and time of departure from the origin point.
     * Departure times, apart from now, must be specified as a dateTime.
     * When a time zone offset is not specified, it will be assumed to be that of the origin point.
     * The departAt value must be in the future. The departAt parameter cannot be used in conjunction with
     * arriveAt.
     *
     * @attribute departAt
     * @param {String} [options.departAt] The properly formatted date with the time or 'now' value. Date
     * cannot be in the past and has to follow ISO 8601 standard.
     */
    departAt: {
        validators: [validators.departAt],
        converters: [converters.dateTime]
    },

    /**
     * The date and time of arrival at the destination point.
     * It must be specified as a dateTime.
     * When a time zone offset is not specified it will be assumed to be that of the destination point.
     * The arriveAt value must be in the future. The arriveAt parameter cannot be used in conjunction with
     * departAt.
     *
     * @attribute arriveAt
     * @param {String} [options.arriveAt] The properly formatted date with a time value. Date cannot be in the past
     * and has to follow ISO 8601 standard.
     */
    arriveAt: {
        validators: [validators.arriveAt],
        converters: [converters.dateTime]
    },

    /**
     * The travel mode for the requested route.
     *
     * Possible values: _car, truck, taxi, bus, van, motorcycle, bicycle, pedestrian_
     *
     * Note that the requested travelMode may not be available for the entire route. Where
     * the requested travelMode is not available for a particular section, the <travelMode> element of the
     * response for that section will be 'other'.
     *
     * @attribute travelMode
     * @param {String} [options.travelMode] The primary means of transportation to be used while routing.
     */
    travelMode: {
        validators: [validators.oneOfValue(['car', 'truck', 'taxi', 'bus', 'van', 'motorcycle', 'bicycle',
            'pedestrian'
        ], 'travel mode')]
    },

    /**
     * Degree of hilliness for thrilling route. Possible values: low, normal, high.
     * This parameter can only be used in conjunction with routeType thrilling.
     *
     * @attribute hilliness
     * @param {String} [options.hilliness] The level of hilliness on a thrilling route.
     */
    hilliness: {
        validators: [validators.oneOfValue(['low', 'normal', 'high'], 'hilliness')]
    },

    /**
     * Level of turns for thrilling route. Possible values: low, normal, high.
     * This parameter can only be used in conjunction with routeType thrilling.
     *
     * @attribute windingness
     * @param {String} [options.windingness] The level of windingness on a thrilling route.
     */
    windingness: {
        validators: [validators.oneOfValue(['low', 'normal', 'high'], 'windingness')]
    },

    /**
     * Specifies which data should be reported for diagnosis purposes.
     *
     * Possible values: _effectiveSettings_
     * Reports the effective parameters or data used when calling the API.
     *
     * In the case of defaulted parameters, the default will be reflected where the parameter was not specified
     * by the caller.
     *
     * @attribute report
     * @param {String} [options.report] The level of a verbose answer from the service, useful for debugging purposes.
     */
    report: {
        validators: [validators.oneOfValue(['effectiveSettings'], 'report')]
    },

    /**
     * Engine type of the vehicle. This parameter is currently only considered for travelMode truck. Valid
     * options are: _combustion, electric_
     *
     * @attribute vehicleEngineType
     * @param {String} [options.vehicleEngineType] The vehicle engine type.
     */
    vehicleEngineType: {
        validators: [validators.oneOfValue(['combustion', 'electric'], 'vehicle engine type')]
    },

    /**
     * Maximum speed of the vehicle in km/hour. A value of 0 means that an appropriate value for the vehicle
     * will be determined and applied during route planning. A non-zero value may be overridden during route
     * planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleMaxSpeed
     * @param {Number} [options.vehicleMaxSpeed] The maximal allowed vehicle speed in km/h.
     */
    vehicleMaxSpeed: {
        validators: [validators.naturalInteger]
    },

    /**
     * Weight of the vehicle in kg. A value of 0 means that weight restrictions are not considered during route
     * planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleWeight
     * @param {Number} [options.vehicleWeight] The vehicle weight in kg (kilograms).
     */
    vehicleWeight: {
        validators: [
            validators.naturalInteger,
            postValidators.vehicleWeight
        ]
    },

    /**
     * Weight per axle of the vehicle in kg (kilograms). A value of 0 means that weight restrictions per axle are not
     * considered during route planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleAxleWeight
     * @param {Number} [options.vehicleAxleWeight] The vehicle axle wight in kg (kilograms).
     */
    vehicleAxleWeight: {
        validators: [validators.naturalInteger]
    },

    /**
     * Length of the vehicle in meters. A value of 0 means that length restrictions are not considered during
     * route planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleLength
     * @param {Number} [options.vehicleLength] The vehicle length in meters.
     */
    vehicleLength: {
        validators: [validators.numberInInterval(0, Number.MAX_VALUE)]
    },

    /**
     * Width of the vehicle in meters. A value of 0 means that width restrictions are not considered during
     * route planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleWidth
     * @param {Number} [options.vehicleWidth] The vehicle width in meters.
     */
    vehicleWidth: {
        validators: [validators.numberInInterval(0, Number.MAX_VALUE)]
    },

    /**
     * Height of the vehicle in meters. A value of 0 means that height restrictions are not considered
     * during route planning. This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleHeight
     * @param {Number} [options.vehicleHeight] The vehicle height in meters.
     */
    vehicleHeight: {
        validators: [validators.numberInInterval(0, Number.MAX_VALUE)]
    },

    /**
     * The vehicle is used for commercial purposes and thus may not be allowed to drive on some roads. This
     * parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleCommercial
     * @param {Boolean} [options.vehicleCommercial] True if the vehicle is used for commercial purposes.
     */
    vehicleCommercial: {
        validators: [validators.bool]
    },

    /**
     * Types of cargo that may be classified as hazardous materials and restricted from some roads. Available
     * vehicleLoadType values are US Hazmat classes 1 through 9, plus generic classifications for use in other
     * countries.
     *
     * __Use these for routing in US__
     * * USHazmatClass1 Explosives
     * * USHazmatClass2 Compressed gas
     * * USHazmatClass3 Flammable liquids
     * * USHazmatClass4 Flammable solids
     * * USHazmatClass5 Oxidizers
     * * USHazmatClass6 Poisons
     * * USHazmatClass7 Radioactive
     * * USHazmatclass8 Corrosives
     * * USHazmatClass9 Miscellaneous
     *
     * __Use these for routing in all other countries__
     * * otherHazmatExplosive Explosives
     * * otherHazmatGeneral Miscellaneous
     * * otherHazmatHarmfulToWater Harmful to water
     *
     * This parameter is currently only considered for travelMode truck.
     *
     * @attribute vehicleLoadType
     * @param {Array|String} [options.vehicleLoadType] The array of truck cargo classification.
     */
    vehicleLoadType: {
        validators: [validators.arrayOfValues(['USHazmatClass1', 'USHazmatClass2', 'USHazmatClass3',
            'USHazmatClass4', 'USHazmatClass5', 'USHazmatClass6', 'USHazmatClass7', 'USHazmatClass8',
            'USHazmatClass9', 'otherHazmatExplosive', 'otherHazmatGeneral', 'otherHazmatHarmfulToWater'
        ],
        'vehicle load type')],
        converters: [converters.arrayOfStrings]
    },
    /**
     * Specifies the speed-dependent component of consumption.
     * Provided as an unordered list of speed/consumption-rate pairs. The list defines points on a
     * consumption curve.
     * Consumption rates for speeds not in the list are found as follows:
     *   *  By linear interpolation, if the given speed lies in between two speeds in the list.
     *   *  By linear extrapolation otherwise, assuming a constant (ΔConsumption/ΔSpeed) determined by
     * the nearest two points in the list.
     *
     * The list must contain between 1 and 25 points (inclusive), and may not
     * contain duplicate points for the same speed. If it only contains a single point,
     * then the consumption rate of that point is used without further processing.
     * Consumption specified for the largest speed must be greater than or equal to that of the
     * penultimate highest speed. This ensures that extrapolation does not lead to negative consumption rates.
     * Similarly, consumption values specified for the two lowest speeds in the list cannot lead to a
     * negative consumption rate for any smaller speed.
     * The minimum and maximum values described here refer to the valid range for the consumption values
     * (expressed in l/100km).
     * @attribute constantSpeedConsumptionInLitersPerHundredkm
     * @param {Array} [options.constantSpeedConsumptionInLitersPerHundredkm] Colon-delimited list of
     * CombustionConstantSpeedConsumptionPairs, e.g., an array of such pairs "60,7".
     */
    constantSpeedConsumptionInLitersPerHundredkm: {
        validators: [
            postValidators.constantSpeedConsumption('combustion')
        ],
        converters: [converters.constantSpeedConsumption]
    },
    /**
     * Specifies the current supply of fuel in liters.
     * @attribute currentFuelInLiters
     * @param {Number} [options.currentFuelInLiters]
     */
    currentFuelInLiters: {
        validators: [
            postValidators.floatAndEngineType('combustion', 'currentFuelInLiters')
        ]
    },
    /**
     * Specifies the amount of fuel consumed for sustaining auxiliary systems of the vehicle, in liters
     *  per hour. It can be used to specify consumption due to devices and systems such as AC systems, radio,
     * heating, etc.
     * @attribute auxiliaryPowerInLitersPerHour
     * @param {Number} [options.auxiliaryPowerInLitersPerHour]
     */
    auxiliaryPowerInLitersPerHour: {
        validators: [
            postValidators.floatAndEngineType('combustion', 'auxiliaryPowerInLitersPerHour')
        ]
    },
    /**
     * Specifies the amount of chemical energy stored in one liter of fuel in megajoules (MJ).
     * It is used in conjunction with the *Efficiency parameters for conversions between saved
     * or consumed energy and fuel.
     * For example, energy density is 34.2 MJ/l for gasoline, and 35.8 MJ/l for Diesel fuel.
     * @attribute fuelEnergyDensityInMJoulesPerLiter
     * @param {Number} [options.fuelEnergyDensityInMJoulesPerLiter]
     */
    fuelEnergyDensityInMJoulesPerLiter: {
        validators: [
            postValidators.fuelEnergyDensityInMJoulesPerLiter
        ]

    },
    /**
     * Specifies the efficiency of converting chemical energy stored in fuel to kinetic energy when the
     * vehicle accelerates (i.e., KineticEnergyGained/ChemicalEnergyConsumed).
     * ChemicalEnergyConsumed is obtained by converting consumed fuel to chemical energy using
     * fuelEnergyDensityInMJoulesPerLiter.
     * @attribute accelerationEfficiency
     * @param {Number} [options.accelerationEfficiency]
     */
    accelerationEfficiency: {
        validators: [
            postValidators.efficiencyParameter('accelerationEfficiency', 'decelerationEfficiency')
        ]
    },
    /**
     * Specifies the efficiency of converting kinetic energy to saved (not consumed) fuel when the
     * vehicle decelerates (i.e., ChemicalEnergySaved/KineticEnergyLost).
     * ChemicalEnergySaved is obtained by converting saved (not consumed) fuel to energy using
     * fuelEnergyDensityInMJoulesPerLiter.
     * @attribute decelerationEfficiency
     * @param {Number} [options.decelerationEfficiency]
     */
    decelerationEfficiency: {
        validators: [
            postValidators.efficiencyParameter('decelerationEfficiency', 'accelerationEfficiency')
        ]
    },
    /**
     * Specifies the efficiency of converting chemical energy stored in fuel to potential energy
     * when the vehicle gains elevation (i.e., PotentialEnergyGained/ChemicalEnergyConsumed).
     * ChemicalEnergyConsumed is obtained by converting consumed fuel to chemical energy using
     * fuelEnergyDensityInMJoulesPerLiter.
     * @attribute uphillEfficiency
     * @param {Number} [options.uphillEfficiency]
     */
    uphillEfficiency: {
        validators: [
            postValidators.efficiencyParameter('uphillEfficiency', 'downhillEfficiency')
        ]
    },
    /**
     * Specifies the efficiency of converting potential energy to saved (not consumed) fuel when the
     * vehicle loses elevation (i.e., ChemicalEnergySaved/PotentialEnergyLost).
     * ChemicalEnergySaved is obtained by converting saved (not consumed) fuel to energy using
     * fuelEnergyDensityInMJoulesPerLiter.
     * @attribute downhillEfficiency
     * @param {Number} [options.downhillEfficiency]
     */
    downhillEfficiency: {
        validators: [
            postValidators.efficiencyParameter('downhillEfficiency', 'uphillEfficiency')
        ]
    },
    /**
     * Specifies the speed-dependent component of consumption.
     * Provided as an unordered list of speed/consumption-rate pairs. The list defines points
     * on a consumption curve.
     * Consumption rates for speeds not in the list are found as follows:
     * *    By linear interpolation, if the given speed lies in between two speeds in the list.
     * *    By linear extrapolation otherwise, assuming a constant (ΔConsumption/ΔSpeed) determined by
     * the nearest two points in the list.
     *
     * The list must contain between 1 and 25 points (inclusive), and may not contain duplicate points
     * for the same speed. If it only contains a single point, then the consumption rate of that point
     * is used without further processing. Consumption specified for the largest speed must be greater
     * than or equal to that of the penultimate highest speed.
     * This ensures that extrapolation does not lead to negative consumption rates.
     * Similarly, consumption values specified for the two lowest speeds in the list cannot lead to a
     * negative consumption rate for any lower speed.
     * The minimum and maximum values described here refer to the valid range for the consumption values
     * (expressed in kWh/100km).
     * @attribute constantSpeedConsumptionInkWhPerHundredkm
     * @param {Array} [options.constantSpeedConsumptionInkWhPerHundredkm] Colon-delimited list of
     * ElectricConstantSpeedConsumptionPairs, e.g., an array of such pairs "60,7".
     */
    constantSpeedConsumptionInkWhPerHundredkm: {
        validators: [
            postValidators.constantSpeedConsumption('electric')
        ],
        converters: [converters.constantSpeedConsumption]
    },
    /**
     * Specifies the current electric energy supply in kilowatt hours (kWh).
     * @attribute currentChargeInkWh
     * @param {Number} [options.currentChargeInkWh]
     */
    currentChargeInkWh: {
        validators: [
            postValidators.chargeParameter('currentChargeInkWh', 'maxChargeInkWh')
        ]
    },
    /**
     * Specifies the maximum electric energy supply in kilowatt hours (kWh) that may be stored in
     * the vehicle's battery.
     * @attribute maxChargeInkWh
     * @param {Number} [options.maxChargeInkWh]
     */
    maxChargeInkWh: {
        validators: [
            postValidators.chargeParameter('maxChargeInkWh', 'currentChargeInkWh')
        ]
    },
    /**
     * Specifies the amount of power consumed for sustaining auxiliary systems in kilowatts (kW).
     * It can be used to specify consumption due to devices and systems such as AC systems, radio, heating, etc.
     * @attribute auxiliaryPowerInkW
     * @param {Number} [options.auxiliaryPowerInkW]
     */
    auxiliaryPowerInkW: {
        validators: [
            postValidators.floatAndEngineType('electric', 'auxiliaryPowerInkW')
        ]
    },

    /**
     * Represents the type of protocol used to perform the service call.
     *
     * @attribute protocol
     * @param {String} [options.protocol] Protocol type, one of _http_ or _https_.
     */
    protocol: {
        validators: [validators.oneOfValue(['http', 'https'], 'protocol')]
    },

    /**
     * A list of shapes to avoid for planning routes. Supported shapes include rectangles.
     * Can contain one of each supported shapes element.

     * @example
     * ```js
     * tt.services.calculateRoute({
     *      locations: '52.37173,4.91015:52.36357,4.898046255',
     *      avoidAreas: [{
     *      southWestCorner: {latitude: 52.36391, longitude:4.90106},
     *      northEastCorner: {latitude: 52.36554, longitude:4.90767}
     * }]);
     * ```
     * Supported point formats:
     * - 'lat,lon'
     * - {lat:111, lon:111}
     * - {lat:111, lng:111}
     * - {latitude:111, longitude:111}
     * - {x:111, y:111}
     * - {lat: function(), lng:function()}
     *
     * @attribute avoidAreas
     * @param {Object[]} [options.avoidAreas] A list of shapes to avoid for planning routes.
     * @throws {TypeError} If the given argument cannot be converted to a valid value.
     */
    avoidAreas: {
        converters: [converters.avoidAreas],
        application: parameterApplications.POST
    },

    /**
     * List of 3-character ISO 3166-1 alpha-3 country codes of countries in which
     * all toll roads with vignettes are to be avoided.
     * Toll roads with vignettes in countries not in the list are unaffected.
     * It is an error to specify both avoidVignette and allowVignette.
     * @attribute avoidVignette
     * @param {String|String[]} [options.avoidVignette] An array of country codes.
     * @throws {TypeError} If the given argument cannot be converted into a valid value.
     */
    avoidVignette: {
        validators: [
            validators.countrySetAlpha3.bind(this, false),
            postValidators.firstParamCannotBeUsedWithSecond('avoidVignette', 'allowVignette')
        ],
        converters: [converters.arrayOfStrings],
        application: parameterApplications.POST
    },

    /**
     * List of 3-character ISO 3166-1 alpha-3 country codes of countries in which
     * toll roads with vignettes are allowed.
     * Specifying allowVignette with some countries X is equivalent to specifying
     * avoidVignette with all countries but X.
     * Specifying allowVignette with an empty list is the same as avoiding all toll roads with vignettes.
     * It is an error to specify both avoidVignette and allowVignette.
     * @attribute allowVignette
     * @param {String|String[]} [options.allowVignette] An array of country codes.
     * @throws {TypeError} If the given argument cannot be converted into a valid value.
     */
    allowVignette: {
        validators: [
            validators.countrySetAlpha3.bind(this, true),
            postValidators.firstParamCannotBeUsedWithSecond('allowVignette', 'avoidVignette')
        ],
        converters: [converters.arrayOfStrings],
        application: parameterApplications.POST
    },

    /**
     * Specifies the electric energy in kWh gained by the vehicle through losing 1000 meters of elevation.
     * * Note: It must be paired with `consumptionInkWhPerkmAltitudeGain`.
     * * Note: It cannot be used with `accelerationEfficiency`, `decelerationEfficiency`, `uphillEfficiency`
     * or `downhillEfficiency`.
     *
     * Minimum value: 0.0
     * Maximum value: `consumptionInkWhPerkmAltitudeGain`
     *
     * @attribute recuperationInkWhPerkmAltitudeLoss
     * @param {Number} [options.recuperationInkWhPerkmAltitudeLoss] Electric energy in kWh
     */
    recuperationInkWhPerkmAltitudeLoss: {
        validators: [
            postValidators.requiresDependantParameter(
                'recuperationInkWhPerkmAltitudeLoss',
                'consumptionInkWhPerkmAltitudeGain'
            ),
            postValidators.consumptionRecuperationAltitudeParameter
        ]
    },

    /**
     * Specifies the electric energy in kWh consumed by the vehicle through gaining 1000 meters of elevation.
     * * Note: It must be paired with `recuperationInkWhPerkmAltitudeLoss`.
     * * Note: It cannot be used with `accelerationEfficiency`, `decelerationEfficiency`, `uphillEfficiency`
     * or `downhillEfficiency`.
     *
     * Minimum value: `recuperationInkWhPerkmAltitudeLoss`
     * Maximum value: 500.0
     *
     * @attribute consumptionInkWhPerkmAltitudeGain
     * @param {Number} [options.consumptionInkWhPerkmAltitudeGain] Electric energy in kWh
     */
    consumptionInkWhPerkmAltitudeGain: {
        validators: [
            postValidators.requiresDependantParameter(
                'consumptionInkWhPerkmAltitudeGain',
                'recuperationInkWhPerkmAltitudeLoss'
            ),
            postValidators.consumptionRecuperationAltitudeParameter
        ]
    },

    /**
     * If specified, the vehicle is subject to ADR tunnel restrictions.
     * * Vehicles with code B are restricted from roads with ADR tunnel categories B, C, D, and E.
     * * Vehicles with code C are restricted from roads with ADR tunnel categories C, D, and E.
     * * Vehicles with code D are restricted from roads with ADR tunnel categories D and E.
     * * Vehicles with code E are restricted from roads with ADR tunnel category E.
     * * If `vehicleAdrTunnelRestrictionCode` is not specified, no ADR tunnel restrictions apply.
     *
     * Notes:
     * * If `travelMode` is pedestrian or bicycle, `vehicleAdrTunnelRestrictionCode` is not considered.
     * * The `vehicleAdrTunnelRestrictionCode` and `vehicleLoadType` parameters are independent; please
     * provide both if applicable.
     *
     * @attribute vehicleAdrTunnelRestrictionCode
     * @param {String} [options.vehicleAdrTunnelRestrictionCode] An ADR restriction code
     * @throws {TypeError} If the given argument is not a valid ADR restriction code.
     */
    vehicleAdrTunnelRestrictionCode: {
        validators: [validators.oneOfValue(['B', 'C', 'D', 'E'], 'vehicleAdrTunnelRestrictionCode')]
    }
};

export default fields;
