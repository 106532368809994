import { SERVICE_TYPES } from 'Core/serviceTypes';
import * as validators from '../validators';
import converters from '../converters';
import urlBuilder from '../../requester/urlBuilder';
import { poiPhotosEndpoint } from 'endpoints';

/**
* Points of Interest (POI) Photos service provides photos of the POI.
* Use this service to generate the URL to retrieve the image.
*
* This service has **specific terms and conditions of usage**. For more information about this service please
* refer to the [POI Photos API documentation](POI_PHOTOS_URL).
*
*
* @class poiPhotos
* @module Services
* @namespace Services.services
* @uses KeyMixin
* @constructor
*
* @param {Object} [options] Options to be passed to the call.
*/
const fields = {
    key: {
        validators: [validators.string],
        required: true
    },
    /**
     * @attribute id
     * @param {Number} [options.id] Photo id which is previously retrieved from the
     * {{#crossLink "Services.services.poiDetails"}}POI Details service{{/crossLink}}.
     */
    id: {
        validators: [validators.string],
        required: true
    },

    /**
     * The maximum height of the image, which will be returned by the service.
     * If only height is provided, the image will be scaled according to that dimension.
     * If none of dimensions is provided, the service will return the original size image.
     * If width is also provided, service will fit, center and crop the image to fill both desired dimensions.
     * If height provided is bigger than the original image's height, the service will return the image
     * with original height.
     *
     * @attribute height
     * @param {Number} [options.height] The maximum height of the image.
     */
    height: {
        validators: [validators.integer],
        converters: [converters.integer]
    },

    /**
     * The maximum width of the image, which will be returned by the service.
     * If only width is provided, the image will be scaled according to that dimension.
     * If none of dimensions is provided, the service will return the original size image.
     * If height is also provided, service will fit, center and crop the image to fill both desired dimensions.
     * If width provided is bigger than the original image's width, the service will return the image
     * with original width.
     *
     * @attribute width
     * @param {Number} [options.width] The maximum width of the image.
     */
    width: {
        validators: [validators.integer],
        converters: [converters.integer]
    }
};

export function poiPhotos(core) {
    function handleServiceCall(data) {
        return 'https://' + urlBuilder(poiPhotosEndpoint, {}, data);
    }
    return core.serviceFactory(fields, SERVICE_TYPES.SEARCH, 'poiPhotos', handleServiceCall);
}
