import {SERVICE_TYPES} from 'Core/serviceTypes';
import reverseGeocodeModel from '../../model/reverseGeocode/reverseGeocode';
import batchModel from '../../model/reverseGeocode/batchReverseGeocode';
import utils from '../utils';
import modelResponse from '../../model/modelResponse';
import {
    reverseGeocodeEndpoint,
    batchReverseGeocodeQueryEndpoint,
    batchSearchEndpoint,
    batchSyncSearchEndpoint
} from 'endpoints';

const TYPES = {
    reverseGeocode: 'reverseGeocode',
    crossStreetLookup: 'reverseGeocode/crossStreet'
};

function ReverseGeocodeBase(options, additionalOptions, core) {
    this.options = options;
    this.additionalOptions = additionalOptions;
    this.fields = {};
    this.core = core;
}

ReverseGeocodeBase.prototype.TYPE = TYPES;
ReverseGeocodeBase.prototype.construct = function(serviceName) {
    const mergedFields = utils.addFields(this.fields, this.defaultFields);

    const service = this.core.serviceFactory(
        mergedFields,
        SERVICE_TYPES.SEARCH,
        serviceName || 'reverseGeocodeBase',
        this.handleServiceCall.bind(this),
        this.handleBatchServiceCall.bind(this)
    );

    return service(this.options, this.additionalOptions);

};
ReverseGeocodeBase.prototype.handleServiceCall = function(data, abortSignal) {
    const singleRequest = this.core.singleRequestServiceFactory(reverseGeocodeEndpoint);
    return singleRequest(this.fields, data, abortSignal)
        .then(response => modelResponse(response, reverseGeocodeModel));
};

ReverseGeocodeBase.prototype.handleBatchServiceCall = function(data, abortSignal) {
    const batchRequest = this.core.batchRequestServiceFactory(100, {
        single: batchReverseGeocodeQueryEndpoint,
        batch: batchSearchEndpoint,
        batchSync: batchSyncSearchEndpoint
    });
    return batchRequest(this.fields, data, abortSignal)
        .then(response => modelResponse(response, batchModel));
};

function reverseGeocodeConstructor(options) {
    return (new ReverseGeocodeBase(options)).construct();
}

reverseGeocodeConstructor.prototype.constructor = ReverseGeocodeBase;

export default reverseGeocodeConstructor;
