import PointFacade from './pointFacade';

const pointFacade = new PointFacade();

export const convertResponsePoints = (elem) => {
    if (!elem) {
        return;
    }

    if (elem.boundingBox) {
        elem.boundingBox.btmRightPoint = pointFacade.convert(elem.boundingBox.btmRightPoint);
        elem.boundingBox.topLeftPoint = pointFacade.convert(elem.boundingBox.topLeftPoint);
    }
    if (elem.viewport) {
        elem.viewport.btmRightPoint = pointFacade.convert(elem.viewport.btmRightPoint);
        elem.viewport.topLeftPoint = pointFacade.convert(elem.viewport.topLeftPoint);
    }
    if (elem.position) {
        elem.position = pointFacade.convert(elem.position);
    }
    if (elem.summary && elem.summary.geoBias) {
        elem.summary.geoBias = pointFacade.convert(elem.summary.geoBias);
    }
};
