import utils from '../utils';
import * as validators from '../validators';
import converters from '../converters';
import commonRoutingParameters from './commonRoutingParameters';
import postValidators from '../postValidators';
import parameterApplications from '../../common/parameterApplications';

/**
 * @private
 * @class RoutingParameters
 * @module Services
 */

let routingParameters = {
    /**
     * Specifies whether to compute alternatives
     * with the objective to find routes that are significantly different,
     * or whether to look for routes better than the reference route. Possible values are:
     *  1. anyRoute: compute alternative routes that are significantly different from the reference route.
     *  2. betterRoute: return an alternative route only if it is better than the reference
     *      route according to the given planning criteria.
     *      If there is a road block on the reference route,
     *      then any alternative that does not contain blockages is considered as a better route.
     *
     * Can only be used when reconstructing a route.
     *
     * @attribute alternativeType
     * @param {String} [options.alternativeType] The type of the alternative route.
     */
    alternativeType: {
        validators: [validators.oneOfValue(['anyRoute', 'betterRoute'], 'alternativeType')]
    },
    /**
     * Locations through which the route is calculated. The
     * following constraints apply:
     * *    At least two locations must be provided.
     * *    The first location in the sequence defines the origin and must be of type 'point'.
     * *    The last location in the sequence defines the destination and must be of type 'point'.
     * *    One or more optional intermediate locations (known as waypoints) may be provided:
     *      *   The maximum allowed number of waypoints is 150.
     *      *   A point waypoint results in an extra leg in the response, a circle waypoint does not.
     *      *   Circle waypoints cannot be used when computeBestOrder is true.
     *
     * The points or circles that will be used to calculate the route. The following input types are allowed:
     *  1. A string with colon-delimited locations. For example '13.42936,52.50931:13.43872,52.50274'
     * where comma divides longitude and latitude.
     *  2. An array containing locations.
     *     Location can be defined as a string, object, or array.
     *
     * <b>Note:</b> Please keep in mind, that `supportingPoints` and 'Waypoints' (meaning more than two `locations`)
     * are mutually exclusive. If you are using `supportingPoints` you can use only two `locations`.
     *
     * @attribute locations
     * @param {String|Array} [options.locations] These are the specified locations for route calculation.
     */
    locations: {
        validators: [validators.routingLocations],
        converters: [converters.routingLocations],
        required: true,
        application: parameterApplications.PATH
    },
    /**
     * The number of desired alternative routes to be calculated. The value provided:
     * Must be an integer in the range 0-5.
     * Using a value greater than 0 in conjunction with computeBestOrder set to true is not allowed.
     * Fewer alternative routes may be returned if either fewer alternatives exist or the requested number of
     * alternatives is larger than the service can calculate. The default value is 0, the maximum value is 5.
     *
     * @attribute maxAlternatives
     * @param {Number} [options.maxAlternatives] To restrict the maximum alternative routes to be calculated.
     */
    maxAlternatives: {
        validators: [validators.numberInInterval(0, 5)]
    },

    /**
     * If specified, guidance instructions will be returned (if available).
     *
     * Possible values:
     * * _coded_ : returns raw instruction data without human-readable messages.
     * * _text_ : returns raw instructions data with human-readable messages in plain text.
     * * _tagged_ : returns raw instruction data with tagged human-readable messages to permit formatting.
     *
     * Note that the instructionsType parameter cannot be used in conjunction with routeRepresentation=none.
     * If alternative routes are requested, instructions will be generated for each route returned.
     *
     * @attribute instructionsType
     * @param {String} [options.instructionsType] If a street number is sent in along with the request, the response
     *     may include the side of the street (Left/Right) and also an offset position for that street number.
     */
    instructionsType: {
        validators: [validators.oneOfValue(['coded', 'text', 'tagged'], 'instructions type')]
    },
    /* eslint-disable max-len */
    /**
     * The language parameter determines the language of the guidance messages. It does not affect proper nouns
     * (the names of streets, plazas, etc.) It has no effect when instructionsType=coded. Allowed values are (a
     * subset of) the IETF language tags described here. The currently supported languages are listed in the
     * supported languages section:
     *
     * [Routing supported languages](CALCULATE_ROUTE_SUPPORTED_LANGS_URL)
     *
     * @attribute language
     * @param {String} [options.language] The language value.
     */
    /* eslint-enable max-len*/
    language: {
        validators: [validators.routingGuidanceLanguage],
        converters: [converters.routingGuidanceLanguage]
    },
    /**
     * Reorders the route waypoints to reduce
     * the route length. Yields best results when used in conjunction with routeType shortest. Possible values:
     * *    true (compute a better order, if possible; not allowed to be used in conjunction with a
     * maxAlternatives value greater than 0; not allowed to be used in conjunction with circle waypoints).
     * The response will include the optimized waypoint indices.
     * *    false (use the locations in the given order). Not allowed to be used in conjunction with
     * routeRepresentation none.
     *
     * @attribute computeBestOrder
     * @param {Boolean} [options.computeBestOrder] A flag to reorder the route waypoints to reduce the route length.
     */
    computeBestOrder: {
        validators: [validators.bool]
    },

    /**
     * Specifies the representation of the set of routes provided as a response. Possible values:
     * * _polyline_ includes routes in the response.
     * * _none_ includes only the optimized waypoint indices but does not include the routes in the response.
     *
     * This parameter value can only be used in conjunction with computeBestOrder 'true'.
     *
     * @attribute routeRepresentation
     * @param {String} [options.routeRepresentation] Allows the routing response to omit the actual route and give only
     *     the best ordered waypoints.
     */
    routeRepresentation: {
        validators: [validators.oneOfValue(['polyline', 'none'], 'route representation')]
    },

    /**
     * Specifies whether to return additional travel times using different types of traffic information (none,
     * historic, live) as well as the default best-estimate travel time. Possible values:
     * * none - do not compute additional travel times.
     * * all - compute travel times for all types of traffic information. Specifying all results in the fields
     * noTrafficTravelTimeInSeconds, historicTrafficTravelTimeInSeconds and
     * liveTrafficIncidentsTravelTimeInSeconds being included in the summaries in the route response.
     *
     * @attribute computeTravelTimeFor
     * @param {String} [options.computeTravelTimeFor] Setting `'all'` provides additional information regarding travel
     *     times.
     */
    computeTravelTimeFor: {
        validators: [validators.oneOfValue(['none', 'all'], 'compute travel time for')]
    },

    /**
     * The directional heading of the vehicle in degrees starting at true North and continuing in a clockwise
     * direction. North is 0 degrees, East is 90 degrees, South is 180 degrees, West is 270 degrees. Possible
     * values are 0-359.
     *
     * @attribute vehicleHeading
     * @param {Number} [options.vehicleHeading] The angle in which the vehicle is heading.
     */
    vehicleHeading: {
        validators: [validators.integerInInterval(0, 359)]
    },

    /**
     * Defines at least how far **in meters** the alternative routes have to follow the reference route.
     *
     * This option cannot be used in conjunction with {{#crossLink "Routing/arriveAt:method"}}{{/crossLink}}.
     *
     * @attribute minDeviationDistance
     * @param {Number} [options.minDeviationDistance] Meters represented as a positive integer number.
     */
    minDeviationDistance: {
        validators: [
            validators.naturalInteger,
            postValidators.firstParamCannotBeUsedWithSecond('minDeviationDistance', 'arriveAt'),
            postValidators.requiresDependantParameter('minDeviationDistance', 'supportingPoints')
        ]
    },

    /**
     * Defines at least how far **in time (seconds)** the alternative routes have to follow the reference route.
     *
     * This option cannot be used in conjunction with {{#crossLink "Routing/arriveAt:method"}}{{/crossLink}}.
     *
     * @attribute minDeviationTime
     * @param {Number} [options.minDeviationTime] Seconds represented as a positive integer number.
     */
    minDeviationTime: {
        validators: [
            validators.naturalInteger,
            postValidators.firstParamCannotBeUsedWithSecond('minDeviationTime', 'arriveAt'),
            postValidators.requiresDependantParameter('minDeviationTime', 'supportingPoints')
        ]
    },

    /* eslint-disable max-len */
    /**
     * Can be used to set supporting points for constructing (or reconstructing) a route and for
     * calculating alternative routes to a base route. The provided sequence of supporting points
     * is used as input for route reconstruction.
     *
     * Pseudo examples:
     * * If the base route is A > B and we include a supporting point C, the result will be A > C > B;
     * * Supporting points can be used to entirely reconstruct a route you previously had:
     * Let us illustrate the problem. To go from A > B you received A > C > D > E > B. If later, you try to generate
     * the same route (A > B), you might get different middle points. So, if you need to generate the exact same route
     * you previously had you can do something like routing().locations(A, B).supportingPoints(C, D, E)
     *
     * You can use one of the following input formats to define the supporting points:
     * * String: '4.8,52.3' or '4.8,52.3:4.87,52.37'
     * * String[]: ['4.8,52.3:4.87,52.37', '4.8,52.3:4.87,52.37']
     * * Object: {lat: 52.3, lon: 4.8} or {lat: 53.3, lng: 5.8} or {x: 53.3, y: 5.8} or {latitude: 53.3, longitude: 5.8}
     * * Object[]: [{lat: 52.3, lon: 4.8}] or [{lat: 53.3, lng: 5.8}]
     *
     * How 'Supporting Points' differ from 'Waypoints?' - Waypoints are points where the calculated route will go through, but
     * the resulting route might be different from search to search. The waypoints order might not necessarilly be respected
     * when calculating the best route. Finally, there is a limit to the number of waypoints you can use in a route calculation.
     * Supporting points can be used to entirely contruct a previous route, or parts of it, using the previously generated points.
     * This means that there is no limit to the number of points, and the order of these points will be respected.</br></br>
     *
     * <b>Note:</b> Please keep in mind, that `supportingPoints` and 'Waypoints' (meaning more than two `locations`) are mutually
     * exclusive. If you are using `supportingPoints` you can use only two `locations`.
     *
     * For more information please refer to the [Routing API Docs](CALCULATE_ROUTE_URL)
     *
     * @attribute supportingPoints
     * @param {String|String[]|Object|Object[]} [options.supportingPoints] Supporting points for route calculation.
     * @example
     * ```js
     * tt.services.routing({
     *      locations: '4.8,52.3:4.87,52.37',
     *      supportingPoints: ['-7.164026,39.319843', '-6.16632,39.342266']
     * })
     *   .then(function(routeGeoJson) {
     *     console.log(routeGeoJson);
     *   });
     * ```
     */
    /* eslint-enable max-len */
    supportingPoints: {
        validators: [validators.supportingPoints],
        converters: [converters.supportingPoints],
        application: parameterApplications.POST
    },

    /**
     * Specifies which of the section types is reported in the route response.
     *
     * Possible values:
     * - `carTrain`, `ferry`, `tunnel` or `motorway`: get
     * sections if the route includes car trains, ferries, tunnels, or motorways.
     * - pedestrian: sections which are only suited for pedestrians.
     * - tollRoad: sections which require a toll to be payed.
     * - tollVignette: sections which require a toll vignette to be present.
     * - country: countries the route has parts in.
     * - travelMode: sections in relation to the request parameter 'travelMode'.
     *
     * The default value is travelMode.
     * @attribute sectionType
     * @param {String|String[]} [options.sectionType] An array of section types.
     */
    sectionType: {
        validators: [validators.arrayOfValues([
            'carTrain', 'country', 'ferry', 'motorway', 'pedestrian', 'tollRoad', 'tollVignette',
            'travelMode', 'tunnel', 'traffic'
        ], 'sectionType')],
        converters: [converters.arrayOfStrings]
    }
};

routingParameters = utils.extend(routingParameters, commonRoutingParameters);

export default function(fieldsToSkip) {
    const result = utils.clone(routingParameters);
    if (fieldsToSkip instanceof Array) {
        fieldsToSkip.forEach(function(field) {
            delete result[field];
        });
    }

    return result;
}
