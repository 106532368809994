import * as validators from '../validators';
import converters from '../converters';
import getRoutingParameters from './routingParameters';
import utils from '../utils';
import parameterApplications from '../../common/parameterApplications';

/**
 * Specifies the efficiency of converting chemical energy stored in fuel to kinetic energy when the
 * vehicle accelerates (i.e., KineticEnergyGained/ChemicalEnergyConsumed).
 * ChemicalEnergyConsumed is obtained by converting consumed fuel to chemical energy using
 * fuelEnergyDensityInMJoulesPerLiter.
 * @attribute accelerationEfficiency
 * @param {Number} [options.accelerationEfficiency]
*/

/**
 * Specifies the amount of fuel consumed for sustaining auxiliary systems of the vehicle, in liters
 *  per hour. It can be used to specify consumption due to devices and systems such as AC systems, radio,
 * heating, etc.
 * @attribute auxiliaryPowerInLitersPerHour
 * @param {Number} [options.auxiliaryPowerInLitersPerHour]
*/

/**
 * Specifies the amount of power consumed for sustaining auxiliary systems in kilowatts (kW).
 * It can be used to specify consumption due to devices and systems such as AC systems, radio, heating, etc.
 * @attribute auxiliaryPowerInkW
 * @param {Number} [options.auxiliaryPowerInkW]
*/

/**
 * Specifies the speed-dependent component of consumption.
 * Provided as an unordered list of speed/consumption-rate pairs. The list defines points
 * on a consumption curve.
 * Consumption rates for speeds not in the list are found as follows:
 * *    By linear interpolation, if the given speed lies in between two speeds in the list.
 * *    By linear extrapolation otherwise, assuming a constant (ΔConsumption/ΔSpeed) determined by
 * the nearest two points in the list.
 *
 * The list must contain between 1 and 25 points (inclusive), and may not contain duplicate points
 * for the same speed. If it only contains a single point, then the consumption rate of that point
 * is used without further processing. Consumption specified for the largest speed must be greater
 * than or equal to that of the penultimate highest speed.
 * This ensures that extrapolation does not lead to negative consumption rates.
 * Similarly, consumption values specified for the two lowest speeds in the list cannot lead to a
 * negative consumption rate for any lower speed.
 * The minimum and maximum values described here refer to the valid range for the consumption values
 * (expressed in kWh/100km).
 * @attribute constantSpeedConsumptionInkWhPerHundredkm
 * @param {Array} [options.constantSpeedConsumptionInkWhPerHundredkm] Colon-delimited list of
 * ElectricConstantSpeedConsumptionPairs, e.g., an array of such pairs "60,7".
*/

/**
 * Specifies the speed-dependent component of consumption.
 * Provided as an unordered list of speed/consumption-rate pairs. The list defines points on a
 * consumption curve.
 * Consumption rates for speeds not in the list are found as follows:
 *   *  By linear interpolation, if the given speed lies in between two speeds in the list.
 *   *  By linear extrapolation otherwise, assuming a constant (ΔConsumption/ΔSpeed) determined by
 * the nearest two points in the list.
 *
 * The list must contain between 1 and 25 points (inclusive), and may not
 * contain duplicate points for the same speed. If it only contains a single point,
 * then the consumption rate of that point is used without further processing.
 * Consumption specified for the largest speed must be greater than or equal to that of the
 * penultimate highest speed. This ensures that extrapolation does not lead to negative consumption rates.
 * Similarly, consumption values specified for the two lowest speeds in the list cannot lead to a
 * negative consumption rate for any smaller speed.
 * The minimum and maximum values described here refer to the valid range for the consumption values
 * (expressed in l/100km).
 * @attribute constantSpeedConsumptionInLitersPerHundredkm
 * @param {Array} [options.constantSpeedConsumptionInLitersPerHundredkm] Colon-delimited list of
 * CombustionConstantSpeedConsumptionPairs, e.g., an array of such pairs "60,7".
*/

/**
 * Specifies the current electric energy supply in kilowatt hours (kWh).
 * @attribute currentChargeInkWh
 * @param {Number} [options.currentChargeInkWh]
 */

/**
 * Specifies the current supply of fuel in liters.
 * @attribute currentFuelInLiters
 * @param {Number} [options.currentFuelInLiters]
 */

/**
 * Specifies the efficiency of converting kinetic energy to saved (not consumed) fuel when the
 * vehicle decelerates (i.e., ChemicalEnergySaved/KineticEnergyLost).
 * ChemicalEnergySaved is obtained by converting saved (not consumed) fuel to energy using
 * fuelEnergyDensityInMJoulesPerLiter.
 * @attribute decelerationEfficiency
 * @param {Number} [options.decelerationEfficiency]
 */

/**
 * Specifies the efficiency of converting potential energy to saved (not consumed) fuel when the
 * vehicle loses elevation (i.e., ChemicalEnergySaved/PotentialEnergyLost).
 * ChemicalEnergySaved is obtained by converting saved (not consumed) fuel to energy using
 * fuelEnergyDensityInMJoulesPerLiter.
 * @attribute downhillEfficiency
 * @param {Number} [options.downhillEfficiency]
 */

/**
 * Specifies the amount of chemical energy stored in one liter of fuel in megajoules (MJ).
 * It is used in conjunction with the *Efficiency parameters for conversions between saved
 * or consumed energy and fuel.
 * For example, energy density is 34.2 MJ/l for gasoline, and 35.8 MJ/l for Diesel fuel.
 * @attribute fuelEnergyDensityInMJoulesPerLiter
 * @param {Number} [options.fuelEnergyDensityInMJoulesPerLiter]
 */

/**
 * Specifies the maximum electric energy supply in kilowatt hours (kWh) that may be stored in
 * the vehicle's battery.
 * @attribute maxChargeInkWh
 * @param {Number} [options.maxChargeInkWh]
 */

/**
 * Specifies the efficiency of converting chemical energy stored in fuel to potential energy
 * when the vehicle gains elevation (i.e., PotentialEnergyGained/ChemicalEnergyConsumed).
 * ChemicalEnergyConsumed is obtained by converting consumed fuel to chemical energy using
 * fuelEnergyDensityInMJoulesPerLiter.
 * @attribute uphillEfficiency
 * @param {Number} [options.uphillEfficiency]
 */

/**
 * If specified, the vehicle is subject to ADR tunnel restrictions.
 * * Vehicles with code B are restricted from roads with ADR tunnel categories B, C, D, and E.
 * * Vehicles with code C are restricted from roads with ADR tunnel categories C, D, and E.
 * * Vehicles with code D are restricted from roads with ADR tunnel categories D and E.
 * * Vehicles with code E are restricted from roads with ADR tunnel category E.
 * * If `vehicleAdrTunnelRestrictionCode` is not specified, no ADR tunnel restrictions apply.
 *
 * Notes:
 * * If `travelMode` is pedestrian or bicycle, `vehicleAdrTunnelRestrictionCode` is not considered.
 * * The `vehicleAdrTunnelRestrictionCode` and `vehicleLoadType` parameters are independent; please
 * provide both if applicable.
 *
 * @attribute vehicleAdrTunnelRestrictionCode
 * @param {String} [options.vehicleAdrTunnelRestrictionCode] An ADR restriction code
 * @throws {TypeError} If the given argument is not a valid ADR restriction code.
 */

/**
 * A setter and getter for the `routeType` attribute.
 *
 * Represents the type of route requested.
 *
 * Notes on specific values:
 *  - `fastest` returns the fastest route.
 *  - `shortest` returns the shortest route by distance.
 *  - `eco` routes balance economy and speed.
 *  - thrilling routes include interesting or challenging roads and use as few motorways as possible. You can
 * choose the level of turns included and also the degree of hilliness. See the hilliness and windingness
 * parameters to set this. There is a limit of 900km on routes planned with routeType=thrilling
 * The default value is fastest.
 *
 * @attribute routeType
 * @param {String} [options.routeType] The route type to be used during route calculation.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'traffic' attribute.
 * Possible values:
 * true (do consider all available traffic information during routing).
 * false (ignore current traffic data during routing). Note that although the current traffic data is
 * ignored during routing, the effect of historic traffic on effective road speeds is still incorporated.
 * The default is true.
 *
 * @attribute traffic
 * @param {String|Boolean} [options.traffic] A boolean flag to determine if traffic data should be used to calculate the
 *     route.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the `avoid` attribute.
 *
 * Specifies something that the route calculation should try to avoid when determining the route.
 * Can be specified multiple times. Possible values:
 *  - `tollRoads` avoids toll roads.
 *  - `motorways` avoids motorways.
 *  - `ferries` avoids ferries.
 *  - `unpavedRoads` avoids unpaved roads.
 *  - `carpools` avoids routes that require use of carpool (HOV/ High Occupancy Vehicle) lanes.
 *  - `alreadyUsedRoads` avoids using the same road multiple times.
 *
 * Most useful in conjunction with `routeType=thrilling`.
 *
 * @attribute avoid
 * @param {Array|String} [options.avoid] Specifies something that the route calculation should try to avoid when
 *     determining the route.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'departAt' attribute.
 * The date and time of departure from the origin point.
 * Departure times apart from now must be specified as a dateTime.
 * When a time zone offset is not specified, it will be assumed to be that of the origin point.
 * The departAt value must be in the future. The departAt parameter cannot be used in conjunction with
 * arriveAt.
 *
 * @attribute departAt
 * @param {String} [options.departAt] The properly formatted date with the time or 'now' value. Date cannot be in the
 *     past and has to follow ISO 8601 standard.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'arriveAt' attribute.
 * The date and time of arrival at the destination point.
 * It must be specified as a dateTime.
 * When a time zone offset is not specified it will be assumed to be that of the destination point.
 * The arriveAt value must be in the future. The arriveAt parameter cannot be used in conjunction with
 * departAt.
 *
 * @attribute arriveAt
 * @param {String} [options.arriveAt] The properly formatted date with a time value. Date cannot be in the past
 * and has to follow ISO 8601 standard.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'travelMode' attribute.
 * The mode of travel for the requested route. Possible values: _car, truck, taxi, bus, van, motorcycle,
 * bicycle, pedestrian_. Note that the requested travelMode may not be available for the entire route. Where
 * the requested travelMode is not available for a particular section, the <travelMode> element of the
 * response for that section will be 'other'.
 *
 * @attribute travelMode
 * @param {String} [options.travelMode] The primary means of transportation to be used while routing.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'hilliness' attribute.
 * The degree of hilliness for thrilling route. Possible values: low, normal, high.
 * This parameter can only be used in conjunction with routeType thrilling.
 *
 * @attribute hilliness
 * @param {String} [options.hilliness] The level of hilliness on a thrilling route.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'windingness' attribute.
 * The level of turns for thrilling route. Possible values: low, normal, high.
 * This parameter can only be used in conjunction with routeType thrilling.
 *
 * @attribute windingness
 * @param {String} [options.windingness] The level of windingness on a thrilling route.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'computeTravelTimeFor' attribute.
 * Specifies whether to return additional travel times using different types of traffic information (none,
 * historic, live) as well as the default best-estimate travel time. Possible values:
 * * none - do not compute additional travel times.
 * * all - compute travel times for all types of traffic information. Specifies all results in the fields
 * noTrafficTravelTimeInSeconds, historicTrafficTravelTimeInSeconds and
 * liveTrafficIncidentsTravelTimeInSeconds being included in the summaries in the route response.
 *
 * @attribute computeTravelTimeFor
 * @param {String} [options.computeTravelTimeFor] Setting `'all'` provides additional information regarding travel
 *     times.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for 'vehicleEngineType' attribute.
 * Engine type of the vehicle. This parameter is currently only considered for travelMode truck. Valid
 * options are: _combustion, electric_.
 *
 * @attribute vehicleEngineType
 * @param {String} [options.vehicleEngineType] The vehicle engine type.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'vehicleMaxSpeed' attribute.
 * Maximum speed of the vehicle in km/hour. A value of 0 means that an appropriate value for the vehicle
 * will be determined and applied during route planning. A non-zero value may be overridden during route
 * planning. This parameter is currently only considered for travelMode truck.
 *
 * @attribute vehicleMaxSpeed
 * @param {Number} [options.vehicleMaxSpeed] The maximal allowed vehicle speed in km/h (kilometers per hour).
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for 'vehicleWeight' attribute.
 * Weight of the vehicle in kg. A value of 0 means that weight restrictions are not considered during route
 * planning. This parameter is currently only considered for travelMode truck.
 *
 * @attribute vehicleWeight
 * @param {Number} [options.vehicleWeight] The vehicle weight in kg (kilograms).
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for 'vehicleAxleWeight' attribute.
 * Weight per axle of the vehicle in kg. A value of 0 means that weight restrictions per axle are not
 * considered during route planning. This parameter is currently only considered for travelMode truck.
 *
 * @attribute vehicleAxleWeight
 * @param {Number} [options.vehicleAxleWeight] The vehicle axle wight in kg.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'vehicleLength' attribute.
 * Length of the vehicle in meters. A value of 0 means that length restrictions are not considered during
 * route planning. This parameter is currently only considered for travelMode truck.
 *
 * @attribute vehicleLength
 * @param {Number} [options.vehicleLength] The vehicle length in meters.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'vehicleWidth' attribute.
 * Width of the vehicle in meters. A value of 0 means that width restrictions are not considered during
 * route planning. This parameter is currently only considered for travelMode truck.
 *
 * @attribute vehicleWidth
 * @param {Number} [options.vehicleWidth] The vehicle width in meters.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'vehicleHeight' attribute.
 * Height of the vehicle in meters. A value of 0 means that height restrictions are not considered
 * during route planning. This parameter is currently only considered for travelMode truck.
 *
 * @attribute vehicleHeight
 * @param {Number} [options.vehicleHeight] The vehicle height in meters.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'vehicleCommercial' attribute.
 * Vehicle is used for commercial purposes and thus may not be allowed to drive on some roads. This
 * parameter is currently only considered for travelMode truck.
 *
 * @attribute vehicleCommercial
 * @param {Boolean} [options.vehicleCommercial] True if the vehicle is used for commercial purposes.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

/**
 * A setter and getter for the 'vehicleLoadType' attribute.
 * Types of cargo that may be classified as hazardous materials and restricted from some roads. Available
 * vehicleLoadType values are US Hazmat classes 1 through 9, plus generic classifications for use in other
 * countries.
 *
 * __Use these for routing in US__
 * * USHazmatClass1 Explosives
 * * USHazmatClass2 Compressed gas
 * * USHazmatClass3 Flammable liquids
 * * USHazmatClass4 Flammable solids
 * * USHazmatClass5 Oxidizers
 * * USHazmatClass6 Poisons
 * * USHazmatClass7 Radioactive
 * * USHazmatclass8 Corrosives
 * * USHazmatClass9 Miscellaneous
 *
 * __Use these for routing in all other countries__
 * * otherHazmatExplosive Explosives
 * * otherHazmatGeneral Miscellaneous
 * * otherHazmatHarmfulToWater Harmful to water
 *
 * This parameter is currently only considered for travelMode truck.
 *
 * @attribute vehicleLoadType
 * @param {Array|String} [options.vehicleLoadType] The array of truck cargo classifications.
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */

const fieldsToSkip = ['locations', 'maxAlternatives', 'instructionsType',
    'language', 'computeBestOrder', 'routeRepresentation', 'vehicleHeading', 'report',
    'callback', 'minDeviationTime', 'minDeviationDistance', 'alternativeType',
    'sectionType', 'supportingPoints', 'allowVignette', 'avoidAreas', 'batchMode',
    'consumptionInkWhPerkmAltitudeGain', 'recuperationInkWhPerkmAltitudeLoss'
];

const fields = getRoutingParameters(fieldsToSkip);

const latLonFieldDescription = {
    validators: [validators.arrayOf({
        point: {
            validators: [validators.objectOf({
                latitude: {
                    validators: [validators.latitude],
                    required: true
                },
                longitude: {
                    validators: [validators.longitude],
                    required: true
                }
            })]
        }
    })],
    converters: [converters.arrayOf({
        point: {
            converters: [converters.objectOf({
                latitude: {
                    converters: [converters.latitude],
                    required: true
                },
                longitude: {
                    converters: [converters.longitude],
                    required: true
                }
            })]
        }
    })],
    required: true,
    application: parameterApplications.POST
};

/**
 * A setter and getter for the 'origins' attribute.
 * A set of origin locations represented by points (latitude/longitude object). At least one origin is required.
 *
 * @attribute origins
 * @param {Array} [options.origins] A set of origin locations represented by points (latitude/longitude object).
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */
fields.origins = latLonFieldDescription;

/**
 * A setter and getter for the 'destinations' attribute.
 * A set of destination locations represented by points (latitude/longitude object).
 * At least one destination is required.
 *
 * @attribute destinations
 * @param {Array} [options.destinations] A set of destination locations represented by points
 * (latitude/longitude object).
 * @throws {TypeError} If the given argument cannot be converted to a valid value.
 */
fields.destinations = latLonFieldDescription;

/**
 * This option lets you manually set how a matrix request should be performed. Otherwise we decide for you if you should
 * use sync, async, or redirect mode. For a further explanation please refer to the Matrix Routing docs
 * [here](MATRIX_ROUTING_URL).
 *
 * @attribute batchMode
 * @param {String} [options.batchMode] Possible values: sync, async, redirect
 */
fields.batchMode = {
    application: parameterApplications.OTHER
};

/**
 * Maximum waiting time for batch download response.
 * This option only works with 'async' and 'redirect' batch modes. Accepts 120 or an integer between 5 and 60.
 *
 * @attribute waitTimeSeconds
 * @param {Number} [options.waitTimeSeconds] Accepts 120 or an integer between 5 and 60.
 */
fields.waitTimeSeconds = {
    application: parameterApplications.QUERY,
    validators: [validators.waitTimeSeconds]
};

export default (fieldsToSkip) => {
    const result = utils.clone(fields);
    if (fieldsToSkip instanceof Array) {
        fieldsToSkip.forEach(function(field) {
            delete result[field];
        });
    }

    return result;
};
