import PointFacade from '../pointFacade';

const pointFacade = new PointFacade();

const toGeoJson = (data) => {
    const coordinates = data.reachableRange.boundary.map(coordinates => [coordinates.lng, coordinates.lat]);
    return {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [coordinates]
        }
    };
};

export default (data) => {
    if (data.reachableRange &&
        data.reachableRange.boundary &&
        Array.isArray(data.reachableRange.boundary)
    ) {
        // convert points
        data.reachableRange.boundary = pointFacade.convert(data.reachableRange.boundary);
        data.reachableRange.center = pointFacade.convert(data.reachableRange.center);
    }

    return Object.assign(data, {
        toGeoJson: () => toGeoJson(data)
    });
};
