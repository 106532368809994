import { incidentViewport } from './services/incidentViewport';
import { trafficFlowSegmentData } from './services/trafficFlowSegmentData';
import { incidentDetails } from './services/incidentDetails';

export default function trafficServiceFactory(core) {
    return {
        incidentViewport: incidentViewport(core),
        trafficFlowSegmentData: trafficFlowSegmentData(core),
        incidentDetails: incidentDetails(core)
    };
}
