/**
 * Matrix Routing service implementation using using the TomTom
 * [Routing API - Matrix Routing](MATRIX_ROUTING_URL).
 *
 * The Routing API Matrix Routing service allows the calculation of a matrix of route summaries for a set of routes
 * which are defined with origin and destination locations.
 *
 * For every given origin this service calculates
 * the cost of routing from that origin to every given destination. The set of origins and the set of destinations
 * can be thought of as the column and row headers of a table, and each cell in the table contains the costs of routing
 * from the origin to the destination for that cell.
 *
 * The following costs are computed for each route:
 * - Travel times
 * - Distances
 *
 * Parameters need to be passed to the constructor.
 *
 * If you want to explicitly decide which batch mode you want to use, add a property 'batchMode' with its value
 * set to one of the properties: sync, async, or redirect.
 *
 * The response is also extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * For more information, please refer to the Matrix Routing documentation
 * [here](MATRIX_ROUTING_URL).
 *
 *
 * @class matrixRouting
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses AbortSignalMixin
 *
 * @constructor
 * @param {Object|Array} [options] Options to be passed to the routing call,
 *     or an array of such options objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 *
 * @example
 * ```js
 * var origins = [
 *   { point: { latitude: 51.63685, longitude: 19.4171 } },
 *   { point: { latitude: 51.63685, longitude: 19 } }
 * ];
 * var destinations = [
 *   { point: { latitude: 52.23498, longitude: 21.00849 } },
 *   { point: { latitude: 52.63685, longitude: 18 } }
 * ];
 * function callbackFn(routeGeoJson) {
 *   console.log(routeGeoJson);
 * }
 * tt.services.matrixRouting({
 *   key: <Your API key>,
 *   origins: origins,
 *   destinations: destinations,
 *   traffic: true
 * }).then(callbackFn);
 * ```
 */
/* jshint nomen:false */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import matrixRoutingModel from 'model/routing/matrixRouting';
import matrixParameters from './matrixParameters';
import modelResponse from '../../model/modelResponse';
import { matrixSyncRoutingEndpoint, matrixRoutingEndpoint } from '../../endpoints/endpoints';

const fields = matrixParameters();
const maxNoOfElementsInBatchRequest = 100;

export function matrixRouting(core) {
    const matrixRequest = core.matrixFactory(maxNoOfElementsInBatchRequest, {
        sync: matrixSyncRoutingEndpoint,
        async: matrixRoutingEndpoint
    });
    const handleServiceCall = (data, abortSignal) => {
        return matrixRequest(fields, data, abortSignal).then(response => modelResponse(response, matrixRoutingModel));
    };

    return core.serviceFactory(fields, SERVICE_TYPES.ROUTING, 'matrixRouting', handleServiceCall);
}
