import {SERVICE_TYPES} from 'Core/serviceTypes';
import * as validators from '../validators';
import converters from '../../services/converters';
import parameterApplications from '../../common/parameterApplications';
import modelResponse from '../../model/modelResponse';
import { autocompleteEndpoint } from 'endpoints';
/**
* The Autocomplete API enables you to make a more meaningful Search API call
* by recognizing entities inside an input query and offering them as query terms.
*
* Makes a search request using the TomTom [Search API - Autocomplete](AUTOCOMPLETE_URL).
*
* ### Response
* The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
* associated with the request.
*
* Please refer to {{#crossLinkModule "Services"}}Difference between
* API responses and this library's responses{{/crossLinkModule}} section.
*
* @class autocomplete
* @module Services
* @namespace Services.services
* @uses KeyMixin
* @uses QueryMixin
* @uses LimitMixin
* @uses CountrySetMixin
* @uses RadiusMixin
* @uses CenterMixin
* @uses AbortSignalMixin
* @constructor
*
* @param {Object} [options]
* @param {Object} [additionalOptions] Additional options to be passed to the service.
*/
const fields = {
    key: {
        validators: [validators.string]
    },
    query: {
        validators: [validators.string],
        required: true,
        application: parameterApplications.PATH
    },
    /**
     * The value should correspond to one of the supported IETF language codes.
     * The list is available [here](SEARCH_API_SUPPORTED_LANGS_URL).
     *
     * The code is case insensitive.
     *
     * @attribute language
     * @param {String} options.language Language code that decides in which language the search results
     * should be returned.
     */
    language: {
        validators: [validators.languageCode],
        converters: [converters.language],
        required: true
    },
    limit: {
        validators: [validators.limit]
    },
    countrySet: {
        validators: [validators.countrySet]
    },
    radius: {
        validators: [validators.naturalInteger]
    },
    /**
     * A result is only included if at least one segment is of any of the indicated types.
     *   Value: A comma-separated list that consists of the types of segments.
     *   Usage examples:
     *   * resultSet=category
     *   * resultSet=brand
     *   * resultSet=category,brand
     * @attribute resultSet
     * @param {String} [options.resultSet] Restricts the result space based on their segment types.
     */
    resultSet: {
        validators: [validators.string]
    },
    center: {
        converters: [converters.point], validators: [validators.point],
        cast: (center, requestOptions) => {
            const coordinates = center.split(',');
            requestOptions.lat = coordinates[0];
            requestOptions.lon = coordinates[1];
        }
    },
    sessionId: {
        validators: [validators.string]
    },
    clientTime: {
        validators: [validators.clientTime]
    }
};

export function autocomplete(core) {
    const singleRequest = core.singleRequestServiceFactory(autocompleteEndpoint);

    function handleServiceCall(data, abortSignal) {
        return singleRequest(fields, data, abortSignal)
            .then(response => modelResponse(response));
    }

    return core.serviceFactory(fields, SERVICE_TYPES.SEARCH, 'autocomplete', handleServiceCall);
}
