/* eslint-disable object-curly-spacing */
/* eslint-disable no-undef */
/**
 * Makes a placeById request using [Place by Id API](PLACE_BY_ID_URL).
 *
 * The Place by Id service endpoint provides detailed information about the Place found by its identifier (entityId).
 * Currently, Place by Id only supports POI (Points of Interest) ids.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * tt.services.fuzzySearch({
 *      key: <Your API key>,
 *      query: "pizza"
 * }).then(function(response) {
 *      tt.services.placeById({
 *          entityId: response.results[0].id,
 *          key: <Your API key>
 *      }).then(function(placeByIdResult) {
 *          console.log(placeByIdResult);
 *      });
 *   });
 * ```
 *
 * @class placeById
 * @module Services
 * @namespace Services.services
 * @uses KeyMixin
 * @uses LanguageMixin
 * @uses AbortSignalMixin
 * @constructor
 *
 * @param {Object} [options]
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import * as validators from '../validators';
import converters from '../converters';
import searchModel from '../../model/search/search';
import modelResponse from '../../model/modelResponse';
import { placeByIdEndpoint } from 'endpoints';

const fields = {
    key: {
        validators: [validators.string]
    },
    /**
     * @attribute entityId
     * @param {String} [options.entityId] The identifier of a specific POI,
     * e.g. `g6Fjo05MRKJpZK81MjgwMDkwMDQyNDY3OTKhdqdVbmlmaWVk`.
     */
    entityId: {
        validators: [validators.string],
        required: true
    },
    language: {
        validators: [validators.languageCode],
        converters: [converters.language]
    }
};

export function placeById(core) {
    const singleRequest = core.singleRequestServiceFactory(placeByIdEndpoint);

    const handleServiceCall = (data, abortSignal) => {
        return singleRequest(fields, data, abortSignal).then(response => modelResponse(response, searchModel));
    };

    return core.serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'placeById',
        handleServiceCall
    );
}
