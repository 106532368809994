
import { calculateReachableRange } from './services/routing/calculateReachableRange';
import { calculateRoute } from './services/routing/calculateRoute';
import { longDistanceEVRouting } from './services/routing/longDistanceEVRouting';
import { matrixRouting } from './services/routing/matrixRouting';

export default function routingServiceFactory(core) {
    return {
        calculateReachableRange: calculateReachableRange(core),
        calculateRoute: calculateRoute(core),
        longDistanceEVRouting: longDistanceEVRouting(core),
        matrixRouting: matrixRouting(core)
    };
}
