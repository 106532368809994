/* eslint-disable object-curly-spacing */
/* eslint-disable no-undef */
import arrayMerge from '../../utils/arrayMerge';
import PointFacade from '../pointFacade';
import { isNil, flatten } from 'lodash';

const pointFacade = new PointFacade();

const incidentCategories = {
    0: 'Unknown',
    1: 'Accident',
    2: 'Fog',
    3: 'Dangerous Conditions',
    4: 'Rain',
    5: 'Ice',
    6: 'Jam',
    7: 'Lane Closed',
    8: 'Road Closed',
    9: 'Road Works',
    10: 'Wind',
    11: 'Flooding',
    12: 'Detour',
    13: 'Cluster',
    14: 'Broken down vehicle'
};

const incidentSeverities = {0: 'unknown', 1: 'minor', 2: 'moderate', 3: 'major', 4: 'undefined'};

const newFeature = (properties, type) => {
    return {
        type: 'Feature',
        geometry: {type: type, coordinates: []},
        properties: properties
    };
};

const newFeatureCollection = properties => {
    const featureCollection = {
        type: 'FeatureCollection',
        features: []
    };
    if (properties) {
        featureCollection.properties = properties;
    }
    return featureCollection;
};

const setProperty = (properties, propertyName, value) => {
    if (value) {
        properties[propertyName] = value;
    }
};

const getFeatures = (poi, preserveCluster) => {
    const features = [];
    const properties = {};
    properties.id = poi.id;
    if (!isNil(poi.cbl) && !isNil(poi.ctr)) {
        properties.clusterBounds = [[poi.cbl.lng, poi.cbl.lat], [poi.ctr.lng, poi.ctr.lat]];
    }
    if (!isNil(poi.op)) {
        properties.originalPosition = [poi.op.lng, poi.op.lat];
    }
    if (!isNil(poi.ic)) {
        properties.incidentCategory = incidentCategories[poi.ic];
    }
    if (!isNil(poi.ty)) {
        properties.incidentSeverity = incidentSeverities[poi.ty];
    }
    if (!isNil(poi.v)) {
        properties.vectorGeometry = poi.v;
    }
    setProperty(properties, 'clusterSize', poi.cs);
    setProperty(properties, 'description', poi.d);
    setProperty(properties, 'incidentCause', poi.c);
    setProperty(properties, 'from', poi.f);
    setProperty(properties, 'to', poi.t);
    setProperty(properties, 'roadNumber', poi.r);
    setProperty(properties, 'delaySeconds', poi.dl);
    setProperty(properties, 'lengthMeters', poi.l);
    setProperty(properties, 'endDate', poi.ed);

    if (poi.cpoi && !preserveCluster) {
        features.push(...flatten(poi.cpoi.map(poi => getFeatures(poi, false))));
    } else {
        if (poi.cpoi) {
            properties.features = flatten(poi.cpoi.map(poi => getFeatures(poi, true)));
        }
        const feature = newFeature(properties, 'Point');
        feature.geometry.coordinates = [poi.p.lng, poi.p.lat];
        features.push(feature);
    }
    return features;
};

export const toGeoJson = (data, preserveCluster) => {
    const result = newFeatureCollection(null),
        json = data[0] || data;
    if (!json || !json.tm || !json.tm.poi) {
        return result;
    }
    if (data.length > 1) {
        // this is edge of the world case
        json.tm.poi = arrayMerge(data[0].tm.poi, data[1].tm.poi, function(first, second) {
            return first.id === second.id;
        });
    }
    for (let i = 0; i < json.tm.poi.length; i += 1) {
        result.features.push(...getFeatures(json.tm.poi[i], preserveCluster));
    }
    return result;
};

const convertPoints = data => {
    const convertResult = incidents => {
        if (!incidents || !incidents.tm || !incidents.tm.poi) {
            return incidents;
        }

        incidents.tm.poi.forEach(poi => {
            if (poi.cbl) {
                poi.cbl = pointFacade.convert(poi.cbl);
            }
            if (poi.ctr) {
                poi.ctr = pointFacade.convert(poi.ctr);
            }
            if (poi.p) {
                poi.p = pointFacade.convert(poi.p);
            }
            if (poi.cpoi && Array.isArray(poi.cpoi)) {
                poi.cpoi.forEach(cpoi => {
                    cpoi.p = pointFacade.convert(cpoi.p);
                });
            }
            if (poi.op) {
                poi.op = pointFacade.convert(poi.op);
            }
        });
        return incidents;
    };

    if (Array.isArray(data)) {
        return data.map(convertResult);
    }
    return convertResult(data);
};

export default (preserveCluster) => {
    return (data) => {
        data = convertPoints(data);
        return Object.assign(data, {
            toGeoJson: () => toGeoJson(data, preserveCluster)
        });
    };
};
